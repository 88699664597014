@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-AddGroupMember {
  position: relative;
  height: 100vh;
  min-height: 480px; }
  #Doctor-AddGroupMember .colorNull {
    color: rgba(51, 51, 51, 0.2); }
  #Doctor-AddGroupMember .addGroupMemberHeader {
    padding: 0 0.04rem; }
  #Doctor-AddGroupMember .searchArea {
    padding: 0 0.04rem;
    height: 0.096rem;
    width: 100%; }
  #Doctor-AddGroupMember .tabArea {
    display: flex;
    padding: 0.032rem 0.04rem;
    position: relative; }
    #Doctor-AddGroupMember .tabArea > div {
      font-size: 0.04rem;
      margin-right: 0.16rem;
      font-weight: 500;
      position: relative;
      z-index: 5; }
      #Doctor-AddGroupMember .tabArea > div > p {
        font-weight: bold;
        margin-bottom: 0;
        position: relative;
        z-index: 10; }
    #Doctor-AddGroupMember .tabArea .choosed {
      width: 0.12rem;
      height: 0.02133rem;
      background: #a0cdff;
      position: absolute;
      bottom: 0;
      z-index: 5; }
    #Doctor-AddGroupMember .tabArea img {
      width: 0.04667rem; }
    #Doctor-AddGroupMember .tabArea img,
    #Doctor-AddGroupMember .tabArea span {
      position: absolute;
      height: 0.04667rem;
      right: 0.04rem;
      font-size: 0.02933rem;
      top: 0.04rem; }
  #Doctor-AddGroupMember .tips {
    padding: 0 0.04rem;
    font-size: 0.02933rem;
    color: #afa7a7; }
    #Doctor-AddGroupMember .tips p {
      margin-bottom: 0; }
  #Doctor-AddGroupMember .choosedList {
    margin: 0 0.04rem;
    border-bottom: 0.00133rem solid #eaeaea;
    font-size: 0.04rem;
    display: flex; }
    #Doctor-AddGroupMember .choosedList span {
      line-height: 0.11733rem;
      height: 0.11733rem;
      margin-right: 0.01333rem; }
    #Doctor-AddGroupMember .choosedList .choosedPatient {
      overflow: auto;
      display: flex;
      width: 0.73333rem;
      margin-bottom: 0.04rem;
      background: #fff; }
      #Doctor-AddGroupMember .choosedList .choosedPatient img {
        width: 0.11733rem;
        height: 0.11733rem;
        border-radius: 100px;
        margin-right: 0.02133rem; }
  #Doctor-AddGroupMember .patientList {
    text-align: left;
    padding: 0 0.04rem;
    overflow: auto;
    padding-bottom: 0.08rem; }
    #Doctor-AddGroupMember .patientList .noGroup {
      text-align: center; }
      #Doctor-AddGroupMember .patientList .noGroup img {
        width: 0.32rem;
        height: 0.32rem;
        margin-top: 0.10667rem; }
      #Doctor-AddGroupMember .patientList .noGroup p {
        font-size: 0.02933rem;
        margin-top: 0.05333rem;
        color: #afa7a7; }
    #Doctor-AddGroupMember .patientList .patientItem {
      margin: 0.05333rem 0;
      height: 0.11733rem;
      display: flex;
      align-items: center; }
      #Doctor-AddGroupMember .patientList .patientItem p {
        margin: 0; }
      #Doctor-AddGroupMember .patientList .patientItem .selectIcon {
        width: 0.04533rem;
        height: 0.04533rem;
        margin-right: 0.036rem; }
      #Doctor-AddGroupMember .patientList .patientItem .photo {
        width: 0.11733rem;
        height: 0.11733rem;
        margin-right: 0.04267rem;
        border-radius: 50%; }
      #Doctor-AddGroupMember .patientList .patientItem .infoArea {
        flex: 1;
        font-size: 0.04rem; }
        #Doctor-AddGroupMember .patientList .patientItem .infoArea p:last-of-type {
          font-size: 0.03467rem; }
  #Doctor-AddGroupMember .bottomArea {
    padding: 0 0.04rem;
    height: 0.13067rem;
    line-height: 0.13067rem;
    border-top: 0.00133rem solid #eaeaea;
    position: absolute;
    bottom: -0.13067rem;
    width: 100%;
    color: #429cff;
    background: #fafafa;
    font-size: 0.04rem;
    opacity: 0;
    transition: bottom 1s, opacity 1s; }
    #Doctor-AddGroupMember .bottomArea .rightArea {
      float: right; }
  #Doctor-AddGroupMember .showBottomArea {
    bottom: 0;
    opacity: 1; }

.DoctorAddMemberModal {
  border-radius: 0.04267rem 0.04267rem 0px 0px;
  height: 90vh;
  overflow: hidden;
  position: unset;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 10vh; }
  .DoctorAddMemberModal .modalHeader {
    padding: 0 0.04rem; }
  .DoctorAddMemberModal .modalHeader {
    height: 0.152rem;
    line-height: 0.152rem;
    display: flex;
    justify-content: space-between;
    font-weight: 500; }
    .DoctorAddMemberModal .modalHeader .sure {
      color: #429cff; }
    .DoctorAddMemberModal .modalHeader .sure:last-child {
      margin-left: 0.08rem; }
  .DoctorAddMemberModal .modalTitle {
    font-size: 0.05067rem;
    font-weight: bold;
    color: #333; }
  .DoctorAddMemberModal .numberInputContainer {
    padding: 0 0.04rem; }
  .DoctorAddMemberModal .numberInput {
    width: 100%;
    height: 0.14133rem;
    line-height: 0.14133rem;
    box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    margin-bottom: 0.032rem; }
    .DoctorAddMemberModal .numberInput .name {
      font-size: 0.04rem;
      font-weight: 700;
      color: #333;
      margin-right: 0.02133rem;
      max-width: 0.33333rem;
      overflow: hidden;
      text-overflow: ellipsis; }
  .DoctorAddMemberModal .am-input-label {
    width: 0.44rem !important;
    font-size: 0.03467rem;
    color: #999;
    display: flex; }
  .DoctorAddMemberModal .am-input-control,
  .DoctorAddMemberModal input {
    float: right;
    width: 0.24rem !important;
    font-size: 0.04rem !important;
    text-align: right; }
  .DoctorAddMemberModal .verifyResultContainer {
    height: 1.17333rem;
    overflow: auto;
    text-align: left;
    padding: 0 0.04rem;
    padding-top: 12px; }
  .DoctorAddMemberModal .verifyResult {
    box-shadow: 0 0 16px 0 rgba(87, 82, 82, 0.2);
    border-radius: 0.01067rem;
    margin-bottom: 0.032rem;
    padding-bottom: 0.04267rem; }
    .DoctorAddMemberModal .verifyResult .titleArea {
      height: 0.14133rem;
      line-height: 0.14133rem;
      padding: 0 0.02133rem;
      position: relative;
      font-size: 0.03467rem;
      display: flex;
      justify-content: space-between; }
      .DoctorAddMemberModal .verifyResult .titleArea .leftArea {
        display: flex;
        align-items: center; }
      .DoctorAddMemberModal .verifyResult .titleArea .name {
        font-size: 0.04rem;
        font-weight: 700;
        color: #333;
        max-width: 0.24rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap; }
      .DoctorAddMemberModal .verifyResult .titleArea .sampleSequence {
        float: right;
        color: #333;
        font-size: 0.04rem; }
    .DoctorAddMemberModal .verifyResult .details {
      margin: 0 0.02133rem;
      font-size: 0.02933rem;
      padding: 0.02133rem; }
      .DoctorAddMemberModal .verifyResult .details > div {
        line-height: 0.05333rem; }
      .DoctorAddMemberModal .verifyResult .details img {
        width: 0.03733rem;
        height: 0.03733rem;
        float: right; }
      .DoctorAddMemberModal .verifyResult .details .patientName {
        color: #333;
        font-weight: 700; }
    .DoctorAddMemberModal .verifyResult .match {
      background: #ecf5ff; }
    .DoctorAddMemberModal .verifyResult .isBand,
    .DoctorAddMemberModal .verifyResult .notExist {
      border: 1px solid #fad291; }
    .DoctorAddMemberModal .verifyResult .notMatch {
      background: #fef6e9; }
  .DoctorAddMemberModal .disable {
    color: #c6e1ff !important; }

.addToGroupComfirm,
.memberNotInList {
  height: 0.8rem;
  width: 0.92rem;
  border-radius: 0.032rem; }
  .addToGroupComfirm .am-modal-content,
  .addToGroupComfirm .am-modal-body,
  .memberNotInList .am-modal-content,
  .memberNotInList .am-modal-body {
    padding: 0; }
  .addToGroupComfirm .content,
  .memberNotInList .content {
    text-align: center;
    margin-bottom: 0.17867rem;
    font-size: 0.03467rem;
    color: rgba(51, 51, 51, 0.5); }
    .addToGroupComfirm .content img,
    .memberNotInList .content img {
      height: 0.09067rem;
      width: 0.09067rem;
      margin: 0.11467rem 0; }
    .addToGroupComfirm .content .firstLine,
    .memberNotInList .content .firstLine {
      font-size: 0.04rem;
      color: #333;
      margin-bottom: 0.032rem; }
  .addToGroupComfirm .buttonContainer > span,
  .memberNotInList .buttonContainer > span {
    width: 0.26667rem;
    height: 0.096rem;
    line-height: 0.096rem;
    display: inline-block;
    border-radius: 0.01067rem;
    font-size: 0.04rem; }
  .addToGroupComfirm .buttonContainer .cancel,
  .memberNotInList .buttonContainer .cancel {
    border: 0.00267rem solid #429cff;
    color: #429cff;
    margin-right: 0.11467rem; }
  .addToGroupComfirm .buttonContainer .sure,
  .memberNotInList .buttonContainer .sure {
    background: #429cff;
    color: #fff; }
