@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1025px) {
  .updatePsdContainer .updatePsdContent {
    background-color: white;
    margin-top: 16px;
    margin-bottom: 100px;
    min-height: calc(100vh - 192px); }
  .updatePsdContainer .layoutContent {
    padding-bottom: 0px; }
  .updatePsdContainer .header .headerLeft {
    display: none; }
  .updatePsdContainer .header .headerRight {
    display: none; }
  .updatePsdContainer .errorMsg {
    color: #f5222d;
    font-size: 0.625vw; }
  .updatePsdContainer .UpdatePsd {
    padding: 48px; }
    .updatePsdContainer .UpdatePsd .headerContainer {
      margin-left: 20.83333vw;
      margin-bottom: 1.77083vw; }
      .updatePsdContainer .UpdatePsd .headerContainer .headerTitle {
        color: #333;
        font-size: 12px;
        margin-bottom: 8px; }
        .updatePsdContainer .UpdatePsd .headerContainer .headerTitle .nameTitle {
          font-size: 16px; }
      .updatePsdContainer .UpdatePsd .headerContainer .headerDis {
        color: rgba(51, 51, 51, 0.5);
        font-size: 12px; }
    .updatePsdContainer .UpdatePsd .stepContainer {
      margin-left: 18.75vw; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepFirstContainer {
        display: flex; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIconContainer {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer {
        margin-left: 0.83333vw; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .steptitle {
          font-size: 16px;
          color: #333;
          font-weight: bold; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .psdTitleErr {
          display: flex;
          margin-top: 1.30208vw;
          align-items: center;
          justify-content: space-between; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .psdTitle {
          font-size: 14px;
          color: #333;
          margin-top: 1.30208vw; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .forgetPsd {
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          color: rgba(51, 51, 51, 0.5); }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIcon {
        height: 24px;
        width: 24px;
        color: white;
        background-color: #429cff;
        display: flex;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.625vw;
        font-size: 14px; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIconDisable {
        height: 24px;
        width: 24px;
        color: white;
        background-color: rgba(51, 51, 51, 0.1);
        display: flex;
        border-radius: 12px;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.625vw;
        font-size: 0.72917vw; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepLine {
        display: flex;
        background-color: #429cff;
        width: 1px;
        flex: 1;
        margin-bottom: 0.52083vw; }
    .updatePsdContainer .UpdatePsd .ant-input {
      width: 21.875vw; }
    .updatePsdContainer .UpdatePsd .ant-form-item {
      margin-bottom: 0px; }
    .updatePsdContainer .UpdatePsd .btnContainer {
      display: flex;
      margin-left: 20.83333vw;
      margin-top: 3.75vw; }
    .updatePsdContainer .UpdatePsd .confirmbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #429cff;
      width: 8.33333vw;
      height: 2.08333vw;
      border: 1px solid #429cff;
      border-radius: 2px;
      margin-right: 5.72917vw;
      cursor: pointer;
      color: white;
      background-color: #429cff; }
      .updatePsdContainer .UpdatePsd .confirmbtn:disabled {
        color: white;
        background-color: rgba(66, 156, 255, 0.2);
        border: 0px; }
    .updatePsdContainer .UpdatePsd .returnBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #429cff;
      width: 8.33333vw;
      height: 2.08333vw;
      border: 1px solid #429cff;
      border-radius: 2px; }
    .updatePsdContainer .UpdatePsd .errorMsg {
      color: #f5222d;
      font-size: 0.02933rem; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .updatePsdContainer .updatePsdContent {
    background-color: white;
    margin-top: 16px;
    margin-bottom: 112px;
    min-height: calc(100vh - 192px); }
  .updatePsdContainer .layoutContent {
    padding-bottom: 0px; }
  .updatePsdContainer .UpdatePsd {
    padding: 48px; }
    .updatePsdContainer .UpdatePsd .headerContainer {
      margin-left: 27.77778vw;
      margin-bottom: 3.14815vw; }
      .updatePsdContainer .UpdatePsd .headerContainer .headerTitle {
        color: #333;
        font-size: 12px;
        margin-bottom: 8px; }
        .updatePsdContainer .UpdatePsd .headerContainer .headerTitle .nameTitle {
          font-size: 1.66667vw; }
      .updatePsdContainer .UpdatePsd .headerContainer .headerDis {
        margin-top: 0.74074vw;
        color: rgba(51, 51, 51, 0.5);
        font-size: 12px; }
    .updatePsdContainer .UpdatePsd .stepContainer {
      margin-left: 24.53704vw; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepFirstContainer {
        display: flex; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIconContainer {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer {
        margin-left: 1.48148vw; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .steptitle {
          font-size: 1.48148vw;
          color: #333;
          font-weight: bold; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .psdTitle {
          font-size: 1.2963vw;
          color: #333;
          margin-top: 2.31481vw; }
        .updatePsdContainer .UpdatePsd .stepContainer .stepMsgContainer .forgetPsd {
          display: flex;
          justify-content: flex-end;
          font-size: 12px;
          color: rgba(51, 51, 51, 0.5); }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIcon {
        height: 2.22222vw;
        width: 2.22222vw;
        color: white;
        background-color: #429cff;
        display: flex;
        border-radius: 1.11111vw;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.11111vw;
        font-size: 1.2963vw; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepIconDisable {
        height: 2.22222vw;
        width: 2.22222vw;
        color: white;
        background-color: rgba(51, 51, 51, 0.1);
        display: flex;
        border-radius: 1.11111vw;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.11111vw;
        font-size: 1.2963vw; }
      .updatePsdContainer .UpdatePsd .stepContainer .stepLine {
        display: flex;
        background-color: #429cff;
        width: 1px;
        flex: 1;
        margin-bottom: 0.92593vw; }
    .updatePsdContainer .UpdatePsd .ant-input {
      width: 29.62963vw; }
    .updatePsdContainer .UpdatePsd .ant-form-item {
      margin-bottom: 0px; }
    .updatePsdContainer .UpdatePsd .btnContainer {
      display: flex;
      margin-left: 27.77778vw;
      margin-top: 6.66667vw; }
    .updatePsdContainer .UpdatePsd .confirmbtn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 12.96296vw;
      height: 3.7037vw;
      border: 1px solid #429cff;
      border-radius: 2px;
      margin-right: 3.88889vw;
      cursor: pointer;
      color: white;
      background-color: #429cff; }
      .updatePsdContainer .UpdatePsd .confirmbtn:disabled {
        color: white;
        background-color: rgba(66, 156, 255, 0.2);
        border: 0px; }
    .updatePsdContainer .UpdatePsd .returnBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #429cff;
      width: 12.96296vw;
      height: 3.7037vw;
      border: 1px solid #429cff;
      border-radius: 2px; } }

@media (max-width: 750px) {
  .mobieContainer {
    padding: 0.05333rem 0.04rem;
    font-size: 0.02933rem;
    color: #333; }
    .mobieContainer .nameTitle {
      font-size: 0.05867rem; }
    .mobieContainer .changeDis {
      color: rgba(51, 51, 51, 0.5);
      margin-top: 0.02133rem; }
    .mobieContainer .orginalTitle {
      font-size: 0.04rem;
      margin-top: 0.064rem;
      margin-bottom: 0.032rem;
      display: flex;
      justify-content: space-between; }
    .mobieContainer .forgetpsd {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-top: 0.02133rem;
      color: rgba(51, 51, 51, 0.5); }
    .mobieContainer .newTitle {
      font-size: 0.04rem;
      margin-bottom: 0.032rem; }
    .mobieContainer .confirmTitle {
      font-size: 0.04rem;
      margin-bottom: 0.032rem;
      margin-top: 0.07467rem; }
    .mobieContainer .btnContainer {
      display: flex;
      margin-top: 0.16rem;
      justify-content: space-between; }
    .mobieContainer .confirmbtn {
      color: white;
      background-color: #429cff; }
      .mobieContainer .confirmbtn:disabled {
        color: white;
        background-color: rgba(66, 156, 255, 0.2);
        border: 0px; }
    .mobieContainer .returnBtn {
      color: #429cff; }
    .mobieContainer .errorMsg {
      color: #f5222d;
      font-size: 0.02933rem; }
    .mobieContainer .ant-input {
      width: 100% !important; }
    .mobieContainer .ant-form-item {
      margin-right: 0px;
      margin-bottom: 0px; }
    .mobieContainer .ant-btn {
      width: 0.42667rem;
      height: 0.10667rem;
      border: 1px solid #429cff; } }
