@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.am-toast-notice-content {
  position: fixed;
  bottom: 10px;
  width: calc(100vw - 20px);
  left: 10px; }

@media (min-width: 1025px) {
  .noMsgTitle {
    color: rgba(51, 51, 51, 0.2); }
  .CodewrapMsg {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 11.97917vw; }
  .listItemCard {
    margin-left: 2%;
    width: 32%;
    box-shadow: 0px 0px 0.00533rem 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.25vw; }
    .listItemCard:nth-child(3n + 1) {
      margin-left: 0px; }
    .listItemCard:hover {
      box-shadow: 0px 0px 1.04167vw 0px rgba(0, 0, 0, 0.15); }
    .listItemCard .icon_tips {
      height: 14px;
      width: 14px;
      margin-left: 0.46875vw; }
    .listItemCard .itemHeader {
      background: #f8fafd;
      color: rgba(51, 51, 51, 0.5);
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      padding: 0.83333vw; }
      .listItemCard .itemHeader .timer {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 11.97917vw; }
      .listItemCard .itemHeader .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 5.88542vw; }
    .listItemCard .itemContentContainer {
      display: flex;
      padding: 1.25vw 0.83333vw 0.83333vw 0.83333vw;
      flex-direction: column;
      background-color: white; }
    .listItemCard .pcCardFooter {
      display: flex;
      font-size: 12px;
      background-color: white;
      justify-content: space-between;
      padding-top: 0.83333vw;
      color: rgba(51, 51, 51, 0.5); }
      .listItemCard .pcCardFooter .visitNumber {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      .listItemCard .pcCardFooter .timer {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 11.97917vw; }
      .listItemCard .pcCardFooter .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 9.375vw; }
    .listItemCard .itemContent {
      display: flex;
      border-bottom: 1px solid #f0f2f5;
      padding-bottom: 0.72917vw; }
      .listItemCard .itemContent .img {
        height: 2.5vw;
        width: 2.5vw;
        border-radius: 1.25vw;
        margin-right: 0.83333vw; }
      .listItemCard .itemContent .contentMsg {
        color: #333;
        font-size: 14px; }
        .listItemCard .itemContent .contentMsg .name {
          display: flex;
          align-items: center;
          font-size: 15px;
          color: #333;
          margin-bottom: 0.83333vw;
          height: 1.5625vw; }
        .listItemCard .itemContent .contentMsg .nameTitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          align-items: center;
          font-size: 16px;
          color: #333;
          max-width: 13.54167vw; }
        .listItemCard .itemContent .contentMsg .tradeCode {
          display: flex;
          margin-bottom: 0.41667vw; }
        .listItemCard .itemContent .contentMsg .patientCode {
          display: flex;
          margin-bottom: 0.41667vw; }
        .listItemCard .itemContent .contentMsg .visitNumber {
          display: flex;
          max-width: 13.54167vw;
          margin-bottom: 0.41667vw; }
    .listItemCard .itemfooter {
      display: flex;
      background-color: #f8fafd;
      padding: 0.72917vw 0.83333vw; }
      .listItemCard .itemfooter .disabledColor {
        color: rgba(0, 0, 0, 0.25) !important; }
      .listItemCard .itemfooter .action {
        display: flex;
        flex: 1;
        color: #429cff;
        font-size: 14px;
        justify-content: center;
        background-color: #f8fafd; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .CodewrapMsg {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 21.2963vw; }
  .listItemCard {
    margin-left: 2%;
    width: 32%;
    box-shadow: 0px 0px 0.00533rem 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 2.22222vw; }
    .listItemCard:nth-child(3n + 1) {
      margin-left: 0px; }
    .listItemCard .newTitle {
      margin-right: 0.92593vw; }
    .listItemCard:hover {
      box-shadow: 0px 0px 1.85185vw 0px rgba(0, 0, 0, 0.15); }
    .listItemCard .icon_tips {
      margin-left: 0.83333vw;
      height: 2.22222vw;
      width: 2.22222vw; }
    .listItemCard .noMsgTitle {
      color: rgba(51, 51, 51, 0.2); }
    .listItemCard .itemHeader {
      background: #f8fafd;
      color: rgba(51, 51, 51, 0.5);
      font-size: 1.11111vw;
      display: flex;
      justify-content: space-between;
      padding: 1.48148vw; }
      .listItemCard .itemHeader .timer {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 30.55556vw; }
      .listItemCard .itemHeader .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 22.22222vw; }
    .listItemCard .pcCardFooter {
      display: none;
      font-size: 12px;
      background-color: white;
      justify-content: space-between;
      padding-top: 1.48148vw;
      color: rgba(51, 51, 51, 0.5); }
      .listItemCard .pcCardFooter .timer {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 21.2963vw; }
      .listItemCard .pcCardFooter .name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 16.66667vw; }
    .listItemCard .itemContent {
      display: flex;
      background-color: white;
      border-bottom: 1px dashed rgba(51, 51, 51, 0.2);
      padding: 1.2963vw; }
      .listItemCard .itemContent .img {
        height: 4.44444vw;
        width: 4.44444vw;
        border-radius: 2.22222vw;
        margin-right: 1.48148vw; }
      .listItemCard .itemContent .contentMsg {
        color: #333;
        font-size: 1.2963vw; }
        .listItemCard .itemContent .contentMsg .name {
          display: flex;
          font-size: 1.48148vw;
          color: #333;
          margin-bottom: 1.48148vw;
          align-items: center; }
        .listItemCard .itemContent .contentMsg .tradeCode {
          display: flex;
          margin-bottom: 0.74074vw; }
        .listItemCard .itemContent .contentMsg .patientCode {
          display: flex;
          margin-bottom: 0.74074vw; }
        .listItemCard .itemContent .contentMsg .visitNumber {
          margin-bottom: 0.74074vw;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 23.14815vw; }
    .listItemCard .itemfooter {
      display: flex;
      padding: 1.2963vw;
      background-color: white;
      padding: 2.22222vw 2.77778vw; }
      .listItemCard .itemfooter .disabledColor {
        color: rgba(0, 0, 0, 0.25); }
      .listItemCard .itemfooter .rightLine {
        border-right: 1px solid rgba(51, 51, 51, 0.2); }
      .listItemCard .itemfooter .action {
        display: flex;
        flex: 1;
        color: #429cff;
        font-size: 14px;
        justify-content: center;
        background-color: white; } }

@media (max-width: 750px) {
  .listItemCard {
    width: 100%;
    box-shadow: 0px 0px 0.00533rem 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 0.032rem;
    background-color: white; }
    .listItemCard .newTitle {
      display: block;
      width: 0.224rem;
      margin-right: 0.04rem; }
    .listItemCard .icon_tips {
      height: 0.032rem;
      width: 0.032rem;
      margin-left: 0.012rem; }
    .listItemCard .noMsgTitle {
      color: rgba(51, 51, 51, 0.2); }
    .listItemCard:hover {
      box-shadow: 0px 0px 0.02667rem 0px rgba(0, 0, 0, 0.15); }
    .listItemCard .itemHeader {
      background: #f8fafd;
      color: rgba(51, 51, 51, 0.5);
      font-size: 0.02933rem;
      display: flex;
      padding: 0.032rem 0.04rem;
      justify-content: space-between; }
      .listItemCard .itemHeader .timer {
        display: flex;
        flex: 1;
        font-size: 0.02933rem;
        min-width: 204px;
        max-width: 0.26667rem; }
      .listItemCard .itemHeader .name {
        display: flex;
        flex: 1;
        max-width: 250px;
        justify-content: flex-end; }
        .listItemCard .itemHeader .name .nameWrap {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 0.21333rem; }
    .listItemCard .itemCardContainer {
      padding: 0.032rem 0.02133rem;
      padding-bottom: 0px; }
    .listItemCard .pcCardFooter {
      display: none; }
    .listItemCard .itemContent {
      display: flex;
      background-color: white;
      border-bottom: 1px dashed rgba(51, 51, 51, 0.2); }
      .listItemCard .itemContent .img {
        height: 0.13333rem;
        width: 0.13333rem;
        margin-right: 0.02133rem; }
      .listItemCard .itemContent .contentMsg {
        color: #333;
        font-size: 0.032rem;
        padding-bottom: 0.02667rem;
        width: 100%; }
        .listItemCard .itemContent .contentMsg .name {
          display: flex;
          font-size: 0.04rem;
          color: #333;
          margin-bottom: 0.032rem;
          align-items: center; }
        .listItemCard .itemContent .contentMsg .nameTitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          align-items: center;
          font-size: 16px;
          color: #333;
          max-width: 0.34667rem; }
        .listItemCard .itemContent .contentMsg .tradeCode {
          display: flex;
          margin-bottom: 0.02133rem; }
        .listItemCard .itemContent .contentMsg .CodewrapMsg {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 0.48rem; }
        .listItemCard .itemContent .contentMsg .patientCode {
          display: flex;
          margin-bottom: 0.02133rem; }
        .listItemCard .itemContent .contentMsg .visitNumber {
          display: flex;
          margin-bottom: 0.02133rem; }
    .listItemCard .itemfooter {
      display: flex;
      padding: 0.01867rem;
      background-color: white;
      padding: 0.032rem 0.04rem; }
      .listItemCard .itemfooter .action {
        display: flex;
        flex: 1;
        color: #429cff;
        font-size: 0.03733rem;
        justify-content: center;
        background-color: white; }
      .listItemCard .itemfooter .disabledColor {
        color: rgba(0, 0, 0, 0.25); }
      .listItemCard .itemfooter .rightLine {
        border-right: 1px solid rgba(51, 51, 51, 0.2); } }
