@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.rowAction {
  display: flex;
  color: #429cff;
  align-items: center;
  width: 200px; }

@media (min-width: 1024px) {
  .icon_tips {
    height: 1.25vw;
    width: 1.25vw;
    margin-left: 0.83333vw; }
  .SeeDisContainer {
    position: relative; }
    .SeeDisContainer .mobileAddVisit {
      display: none; }
    .SeeDisContainer .listContainer {
      padding-bottom: 40px; }
    .SeeDisContainer .sear {
      width: 400px;
      margin: auto; }
    .SeeDisContainer .addOneCaseContainer {
      display: flex;
      justify-content: flex-end;
      flex: 1; }
    .SeeDisContainer .addOneCase {
      border: 1px solid #429cff;
      color: #429cff;
      padding: 0.3125vw 0.625vw;
      background-color: white;
      border-radius: 2px; }
    .SeeDisContainer .addOneCaseDisabel {
      color: rgba(51, 51, 51, 0.2);
      border: 1px solid rgba(51, 51, 51, 0.2); }
    .SeeDisContainer .heig {
      display: block;
      height: 67px; }
    .SeeDisContainer .PageInfoCompount {
      display: none; }
    .SeeDisContainer .fin {
      color: #63be00; }
    .SeeDisContainer .con {
      color: #f5a623; }
    .SeeDisContainer .table {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1); }
    .SeeDisContainer .pa {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px; }
    .SeeDisContainer .ant-table-body .ant-table-row {
      height: 56px;
      line-height: 56px; }
    .SeeDisContainer .ant-table-body .ant-table-thead {
      height: 48px;
      line-height: 48px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody tr:nth-child(2n + 1) {
      border-bottom: none;
      background: #fff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody tr:nth-child(2n) {
      background: #f8fafd;
      border-bottom: none; }
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th {
      background: #f8fafd;
      padding: 0;
      border-bottom: none; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr {
      padding: 0; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td {
      border-bottom: none;
      padding: 0; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:first-child,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:first-child {
      padding-left: 24px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:last-child,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      padding-right: 24px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:last-child > div,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      justify-content: flex-end;
      text-align: right; }
    .SeeDisContainer .ant-table-body .ant-table-row-hover > td {
      background: unset !important; }
    .SeeDisContainer .ant-table-body .ant-table-row-hover:hover {
      background: #ecf5ff !important; }
    .SeeDisContainer .ant-table-body .rowAction .ant-btn {
      padding-left: 0; }
    .SeeDisContainer .ant-table-body .rowAction .ant-btn:last-child {
      padding-right: 0 !important; }
    .SeeDisContainer .ant-pagination-item {
      border-radius: 100%; }
    .SeeDisContainer .ant-pagination-prev,
    .SeeDisContainer .ant-pagination-item-link {
      border-radius: 100%; }
    .SeeDisContainer .ant-pagination-item-active {
      background: #429cff;
      border: none; }
      .SeeDisContainer .ant-pagination-item-active a {
        color: white; }
    .SeeDisContainer .downloadBtn {
      text-align: center; }
    .SeeDisContainer .toSeeGauge::after {
      content: '';
      height: 12px;
      width: 1px;
      top: 5px;
      right: -6px;
      background-color: #1890ff;
      position: absolute; }
    .SeeDisContainer .disabledColor {
      color: rgba(0, 0, 0, 0.25); }
    .SeeDisContainer .downloadBtn::after {
      content: '';
      height: 12px;
      width: 1px;
      top: 5px;
      right: 5px;
      background-color: #1890ff;
      position: absolute; }
    .SeeDisContainer .a1 {
      display: inline-block;
      padding-right: 7px;
      border-right: 1px solid #d6d6d6;
      margin-right: 7px; }
    .SeeDisContainer .patientInfo {
      display: flex;
      align-items: center;
      height: 52px; }
      .SeeDisContainer .patientInfo .patienCode {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 20.83333vw; }
      .SeeDisContainer .patientInfo .patienName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 20.83333vw;
        margin-left: 4.16667vw; }
    .SeeDisContainer .ant-btn {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
      padding-right: 7px;
      color: #429cff;
      padding-left: 0; }
      .SeeDisContainer .ant-btn:disabled {
        color: rgba(0, 0, 0, 0.2); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .SeeDisContainer {
    /* .mar {
    margin-left: 20px;
  } */ }
    .SeeDisContainer .listContainer {
      padding-bottom: 40px; }
    .SeeDisContainer .sear {
      width: 400px;
      margin: auto; }
    .SeeDisContainer .icon_tips {
      margin-left: 0.83333vw;
      height: 2.22222vw;
      width: 2.22222vw; }
    .SeeDisContainer .addOneCaseContainer {
      display: flex;
      justify-content: flex-end;
      flex: 1; }
    .SeeDisContainer .addOneCase {
      border: 1px solid #429cff;
      color: #429cff;
      padding: 0.55556vw 1.11111vw;
      background-color: white;
      border-radius: 2px; }
    .SeeDisContainer .heig {
      display: block;
      height: 67px; }
    .SeeDisContainer .content {
      min-height: calc(100vh - 167px - 0.5rem);
      padding-top: 16px; }
    .SeeDisContainer .PageInfoCompount {
      display: none; }
    .SeeDisContainer .fin {
      color: #63be00; }
    .SeeDisContainer .con {
      color: #f5a623; }
    .SeeDisContainer .table {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1); }
    .SeeDisContainer .ant-table-body .ant-table-row {
      height: 56px;
      line-height: 56px; }
    .SeeDisContainer .ant-table-body .ant-table-thead {
      height: 48px;
      line-height: 48px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody tr:nth-child(2n + 1) {
      border-bottom: none;
      background: #fff; }
    .SeeDisContainer .ant-table-body .ant-table-tbody tr:nth-child(2n) {
      background: #f8fafd;
      border-bottom: none; }
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th {
      background: #f8fafd;
      padding: 0;
      border-bottom: none; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr {
      padding: 0; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td {
      border-bottom: none;
      padding: 0; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:first-child,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:first-child {
      padding-left: 24px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:last-child,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      padding-right: 24px; }
    .SeeDisContainer .ant-table-body .ant-table-tbody > tr > td:last-child > div,
    .SeeDisContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      justify-content: flex-end;
      text-align: right; }
    .SeeDisContainer .ant-table-body .ant-table-row-hover > td {
      background: unset !important; }
    .SeeDisContainer .ant-table-body .ant-table-row-hover:hover {
      background: #ecf5ff !important; }
    .SeeDisContainer .ant-table-body .rowAction .ant-btn {
      padding-left: 0; }
    .SeeDisContainer .ant-table-body .rowAction .ant-btn:last-child {
      padding-right: 0 !important; }
    .SeeDisContainer .pa {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px; }
    .SeeDisContainer .ant-pagination-item {
      border-radius: 100%; }
    .SeeDisContainer .ant-pagination-prev,
    .SeeDisContainer .ant-pagination-item-link {
      border-radius: 100%; }
    .SeeDisContainer .ant-pagination-item-active {
      background: #429cff;
      border: none; }
      .SeeDisContainer .ant-pagination-item-active a {
        color: white; }
    .SeeDisContainer .downloadBtn {
      text-align: center; }
    .SeeDisContainer .toSeeGauge::after {
      content: '';
      height: 12px;
      width: 1px;
      top: 5px;
      right: -6px;
      background-color: #1890ff;
      position: absolute; }
    .SeeDisContainer .disabledColor {
      color: rgba(0, 0, 0, 0.25); }
    .SeeDisContainer .downloadBtn::after {
      content: '';
      height: 12px;
      width: 1px;
      top: 5px;
      right: 5px;
      background-color: #1890ff;
      position: absolute; }
    .SeeDisContainer .a1 {
      display: inline-block;
      padding-right: 7px;
      border-right: 1px solid #d6d6d6;
      margin-right: 7px; }
    .SeeDisContainer .patientInfo {
      display: flex;
      padding: 10px 0px; }
      .SeeDisContainer .patientInfo .patienCode {
        display: flex; }
      .SeeDisContainer .patientInfo .patienName {
        display: flex;
        justify-content: flex-end;
        margin-left: 0.10667rem; }
    .SeeDisContainer .rowAction {
      display: flex;
      color: #429cff;
      align-items: center;
      width: 200px; }
    .SeeDisContainer .moreAction {
      border-left: 1px solid #d6d6d6;
      padding-left: 7px; }
    .SeeDisContainer .ant-btn {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      box-shadow: none;
      padding-right: 7px;
      color: #429cff; }
      .SeeDisContainer .ant-btn:disabled {
        color: rgba(0, 0, 0, 0.2); } }

@media (max-width: 750px) {
  .SeeDisContainer {
    /* -------------------------- */ }
    .SeeDisContainer .layoutContent {
      min-height: calc(100vh - 150px);
      background: #f0f2f5;
      margin-top: 0.13333rem; }
    .SeeDisContainer .icon_tips {
      height: 0.04667rem;
      width: 0.04667rem;
      margin-left: 0.012rem; }
    .SeeDisContainer .addOneCaseContainer {
      display: none; }
    .SeeDisContainer .mobileAddVisit {
      position: fixed;
      z-index: 900;
      top: 0.21333rem;
      right: 0px;
      display: flex;
      background-color: white;
      color: #429cff;
      font-size: 0.04rem;
      height: 0.096rem;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      padding: 0px 0.02667rem;
      align-items: center;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1); }
    .SeeDisContainer .addOneCaseDis {
      color: rgba(51, 51, 51, 0.2); }
    .SeeDisContainer .ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
      background-color: transparent;
      border-color: #d6d6d6;
      border-right: none;
      border-radius: 4px 0 0 4px;
      background-color: #f0f2f5;
      /* left: -100%; */ }
    .SeeDisContainer .listContainer {
      margin-top: 10px; }
    .SeeDisContainer .ant-input.ant-input-lg {
      border: 1px solid #d6d6d6; }
    .SeeDisContainer .ant-input.ant-input-lg {
      width: calc(100% - 60px);
      border-left: none;
      border-radius: 0 4px 4px 0;
      background-color: #f0f2f5; }
    .SeeDisContainer .ant-input-group {
      display: inline-flex;
      margin-left: 10px;
      margin-bottom: 0.02999rem;
      position: fixed;
      /* background-color: $bg-color; */
      border-left: none !important; }
      .SeeDisContainer .ant-input-group .ant-input.ant-input-lg {
        order: 1; }
    .SeeDisContainer .ant-input:focus {
      border-color: #d6d6d6;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: none; }
    .SeeDisContainer .ant-input-group-addon:last-child {
      width: 40px;
      border-right: none;
      border-radius: 4px 0 0 4px; }
    .SeeDisContainer .header {
      width: 100% !important;
      padding-left: 0.04rem;
      padding-right: 0.04rem; }
      .SeeDisContainer .header .header_name {
        position: relative;
        top: 0;
        margin-left: 0.052rem;
        left: -30%;
        float: left;
        display: block; }
      .SeeDisContainer .header .userName-dropdown {
        position: relative;
        top: 0;
        left: 0;
        float: right;
        display: block; }
    .SeeDisContainer .sear {
      background-color: white;
      height: 0.07496rem; }
    .SeeDisContainer .center {
      display: flex;
      align-items: center;
      justify-content: center; }
    .SeeDisContainer .content {
      background-color: #f0f2f5; }
    .SeeDisContainer .upRow {
      font-size: 0.02933rem;
      padding-top: 0.01799rem;
      display: flex; }
      .SeeDisContainer .upRow .doctorName {
        display: flex;
        flex: 2; }
      .SeeDisContainer .upRow .qrtime {
        display: flex;
        flex: 2; }
      .SeeDisContainer .upRow .statusIcon {
        display: flex;
        width: 0.24rem;
        justify-content: flex-end; }
        .SeeDisContainer .upRow .statusIcon .anticonIcon {
          font-size: 0.03733rem; }
    .SeeDisContainer .downRow {
      display: flex;
      font-size: 0.03733rem;
      padding: 0.05333rem 0px;
      border-bottom: 1px dashed rgba(51, 51, 51, 0.2); }
      .SeeDisContainer .downRow .qrName {
        display: flex;
        flex: 3;
        align-items: center; }
      .SeeDisContainer .downRow .visitTime {
        display: flex;
        flex: 2;
        justify-content: flex-end; }
    .SeeDisContainer .btnGP {
      display: flex;
      padding-top: 0.032rem; }
      .SeeDisContainer .btnGP .actionBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1; }
      .SeeDisContainer .btnGP .leftLine {
        border-left: 1px solid rgba(51, 51, 51, 0.2); }
    .SeeDisContainer .a1 {
      width: 100%; }
    .SeeDisContainer .status {
      /* border-right: 1px solid $table-sm-color; */
      height: 0.06597rem; }
      .SeeDisContainer .status::after {
        position: absolute;
        top: 27.3%;
        display: block;
        height: 45.4%;
        width: 1px;
        background-color: #d6d6d6;
        left: calc(100% - 0.5px);
        content: ''; }
    .SeeDisContainer .action {
      height: 0.06597rem; }
    .SeeDisContainer .heig {
      height: 0; }
    .SeeDisContainer .PageInfoCompount {
      display: none; }
    .SeeDisContainer .header_name {
      display: initial; }
    .SeeDisContainer .mo-dataList {
      background-color: white;
      padding: 0.032rem 0.04rem;
      margin-bottom: 10px; }
    .SeeDisContainer .moList {
      min-height: 70vh;
      overflow: scroll; }
    .SeeDisContainer .patientInfo {
      display: flex;
      background-color: white;
      width: 100%;
      z-index: 50;
      padding-top: 0rem;
      padding-bottom: 0.04267rem;
      padding-left: 0.04rem;
      padding-right: 0.04rem;
      justify-content: space-between; }
      .SeeDisContainer .patientInfo .patienCode {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 0.4rem; }
      .SeeDisContainer .patientInfo .patienName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 0.4rem; }
    .SeeDisContainer .scanActionIcon {
      margin-left: 0.01333rem;
      height: 0.036rem;
      width: 0.036rem; } }
