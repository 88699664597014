@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.expertInfo {
  padding: 0.04rem;
  position: relative; }
  .expertInfo .backColor {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    z-index: 0; }
  .expertInfo .docCardContainer {
    position: relative;
    height: 0.26667rem;
    width: 0.92rem;
    box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    margin-top: 0.02667rem;
    margin-bottom: 0.04267rem; }
    .expertInfo .docCardContainer .expertCardMsg {
      display: flex;
      padding: 0.05333rem 0.032rem;
      width: 0.92rem;
      height: 0.26667rem;
      border-radius: 8px;
      margin-top: -0.26667rem; }
      .expertInfo .docCardContainer .expertCardMsg .expertMsg {
        justify-items: center;
        padding-left: 0.05333rem; }
      .expertInfo .docCardContainer .expertCardMsg .expertImg {
        height: 0.16rem;
        width: 0.16rem; }
        .expertInfo .docCardContainer .expertCardMsg .expertImg .img {
          width: 100%; }
      .expertInfo .docCardContainer .expertCardMsg .expertName {
        font-size: 0.04533rem;
        font-weight: bold;
        color: #333; }
      .expertInfo .docCardContainer .expertCardMsg .expertPosition {
        display: flex;
        font-size: 0.032rem;
        color: rgba(51, 51, 51, 0.5);
        align-items: flex-end;
        margin-left: 0.032rem; }
      .expertInfo .docCardContainer .expertCardMsg .nameContainer {
        display: flex;
        margin-bottom: 0.02133rem; }
      .expertInfo .docCardContainer .expertCardMsg .qrImg {
        margin-left: 0.03467rem;
        width: 0.04267rem;
        height: 0.04267rem; }
  .expertInfo .gridContainer {
    display: flex;
    justify-content: space-between;
    cursor: pointer; }
  .expertInfo .gridItemCard {
    width: 0.43733rem;
    border: 8px;
    position: relative; }
    .expertInfo .gridItemCard .msgContainerFont {
      padding-left: 0.032rem;
      position: absolute;
      color: #333;
      bottom: 20px;
      left: 0;
      font-size: 0.02933rem; }
      .expertInfo .gridItemCard .msgContainerFont .itemTitle {
        font-size: 0.04rem;
        margin-bottom: 0.01333rem;
        font-weight: bold; }
  .expertInfo .wx_nav_box_b {
    position: relative;
    margin-top: 16px; }
    .expertInfo .wx_nav_box_b .my_nav_bottom {
      width: 100%; }
      .expertInfo .wx_nav_box_b .my_nav_bottom img {
        height: 80px; }
    .expertInfo .wx_nav_box_b .name_nav_box {
      position: absolute;
      right: 0.032rem;
      top: 50%;
      transform: translateY(-50%);
      text-align: right; }
      .expertInfo .wx_nav_box_b .name_nav_box .name_nav_box1 {
        font-size: 0.04rem;
        font-weight: bold; }
      .expertInfo .wx_nav_box_b .name_nav_box .name_nav_box2 {
        font-size: 0.02933rem;
        font-weight: 400;
        color: rgba(51, 51, 51, 0.5); }
