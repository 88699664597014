@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.PatientMsg {
  padding: 0 0.04rem;
  height: 100%; }
  .PatientMsg .patientMsgCard {
    display: flex;
    padding: 0.04267rem 0.032rem;
    box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    background: white; }
    .PatientMsg .patientMsgCard .portrait {
      width: 0.12rem;
      height: 0.12rem;
      border-radius: 50%; }
    .PatientMsg .patientMsgCard .pmsg {
      flex: 1;
      margin-left: 0.032rem; }
      .PatientMsg .patientMsgCard .pmsg .msgRow {
        display: flex;
        flex: 1; }
        .PatientMsg .patientMsgCard .pmsg .msgRow .nameRow {
          display: flex;
          flex: 1;
          align-items: center;
          font-size: 0.03467rem;
          color: rgba(51, 51, 51, 0.5); }
          .PatientMsg .patientMsgCard .pmsg .msgRow .nameRow .name {
            font-size: 0.04533rem;
            font-weight: bold;
            color: #333;
            margin-right: 0.05333rem; }
          .PatientMsg .patientMsgCard .pmsg .msgRow .nameRow .sex {
            margin-right: 0.05333rem; }
          .PatientMsg .patientMsgCard .pmsg .msgRow .nameRow span:first-child {
            color: #333; }
        .PatientMsg .patientMsgCard .pmsg .msgRow .editAction {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          color: #429cff;
          font-size: 0.03467rem; }
      .PatientMsg .patientMsgCard .pmsg .codeRow {
        display: flex;
        color: #333;
        font-size: 0.03467rem;
        flex: 1; }
        .PatientMsg .patientMsgCard .pmsg .codeRow .number {
          margin-left: 0.02rem; }
      .PatientMsg .patientMsgCard .pmsg .discription {
        font-size: 0.03467rem;
        color: rgba(51, 51, 51, 0.5); }
      .PatientMsg .patientMsgCard .pmsg .taskMarkIcons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0.01499rem; }
  .PatientMsg .groupTag {
    padding: 0.01333rem 0.02667rem;
    background-color: rgba(66, 156, 255, 0.1);
    color: rgba(51, 51, 51, 0.8);
    border-radius: 8px;
    margin-right: 0.032rem;
    margin-top: 0.02133rem;
    font-size: 0.02933rem; }
    .PatientMsg .groupTag:last-child {
      margin-right: 0rem; }
  .PatientMsg .notiTypeContainer {
    display: flex;
    align-items: center;
    margin-top: 0.05333rem; }
    .PatientMsg .notiTypeContainer .notiType {
      background-color: rgba(66, 156, 255, 0.1);
      border-radius: 0.048rem;
      padding: 0.01867rem 0.04533rem;
      font-weight: bold;
      font-size: 0.04rem;
      color: #429cff;
      margin-right: 0.04267rem; }
    .PatientMsg .notiTypeContainer .notiTypeSel {
      background-color: #429cff;
      border-radius: 0.048rem;
      padding: 0.01867rem 0.04533rem;
      font-weight: bold;
      font-size: 0.04rem;
      color: white;
      margin-right: 0.04267rem; }
    .PatientMsg .notiTypeContainer .total {
      float: right;
      line-height: 0.04798rem;
      color: #333;
      opacity: 0.5;
      font-size: 0.02933rem; }
  .PatientMsg .timerPickerContainer {
    display: flex;
    align-items: center;
    margin: 0.04rem 0px; }
    .PatientMsg .timerPickerContainer .spaceLiner {
      display: flex;
      align-items: center;
      font-size: 0.05333rem;
      margin: 0px 0.05333rem; }
    .PatientMsg .timerPickerContainer .timerShow {
      color: #333;
      font-size: 0.03467rem; }
    .PatientMsg .timerPickerContainer .triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 4px solid black;
      border-right: 4px solid rgba(255, 0, 0, 0);
      border-left: 4px solid rgba(0, 0, 255, 0);
      border-bottom: 3px solid rgba(0, 0, 255, 0);
      margin-left: 6px; }
  .PatientMsg .notifyList {
    overflow: hidden; }
    .PatientMsg .notifyList .notiItemContainer {
      box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
      border-radius: 8px;
      background: white;
      padding: 0.04rem 0.02667rem;
      margin-top: 0.03733rem;
      margin-bottom: 0.03733rem; }
      .PatientMsg .notifyList .notiItemContainer .msgContent {
        padding-bottom: 0.032rem; }
      .PatientMsg .notifyList .notiItemContainer .titleRow {
        display: flex;
        justify-content: flex-end; }
        .PatientMsg .notifyList .notiItemContainer .titleRow .titleMsg {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #333;
          font-size: 0.04533rem;
          font-weight: bold;
          width: 0.6rem;
          flex: 1; }
        .PatientMsg .notifyList .notiItemContainer .titleRow .time {
          display: flex;
          justify-content: flex-end;
          margin-left: 0.05733rem; }
      .PatientMsg .notifyList .notiItemContainer .typesRow {
        display: flex;
        margin-top: 0.02933rem;
        align-items: center; }
        .PatientMsg .notifyList .notiItemContainer .typesRow .typeLabel {
          display: flex;
          flex: 1; }
      .PatientMsg .notifyList .notiItemContainer .iconRow {
        display: flex;
        align-items: center; }
        .PatientMsg .notifyList .notiItemContainer .iconRow .notReaded {
          color: rgba(51, 51, 51, 0.2);
          margin-right: 0.03333rem; }
        .PatientMsg .notifyList .notiItemContainer .iconRow .moreIcon {
          height: 0.03467rem;
          width: 0.03467rem; }
      .PatientMsg .notifyList .notiItemContainer .visitReQtiemRow {
        display: flex;
        justify-content: space-between;
        margin-top: 0.032rem; }
      .PatientMsg .notifyList .notiItemContainer .radioGroup {
        display: flex;
        padding-top: 0.032rem;
        border-top: 1px solid rgba(51, 51, 51, 0.1); }
