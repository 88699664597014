@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.mobileHeader {
  font-size: 0.04rem;
  padding: 0.02249rem 0;
  margin-bottom: 0.01799rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .mobileHeader .leftContiner {
    flex: 1; }
    .mobileHeader .leftContiner span {
      display: inline-block; }
    .mobileHeader .leftContiner .iconBack {
      width: 0.04rem;
      height: 0.04rem;
      margin-right: 0.02667rem;
      vertical-align: -2px; }
  .mobileHeader .titleContainer {
    flex: 1;
    display: flex;
    justify-content: center; }
  .mobileHeader .rigthtContiner {
    text-align: right;
    color: #429cff;
    font-size: 0.04rem; }
    .mobileHeader .rigthtContiner button {
      font-size: 0.04rem !important; }
    .mobileHeader .rigthtContiner button:disabled {
      color: rgba(66, 156, 255, 0.3); }
    .mobileHeader .rigthtContiner button:last-of-type[class*='link'] {
      height: auto;
      padding-right: 0; }
