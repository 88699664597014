@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#SignIn .aaa1 {
  color: black !important; }

#SignIn .SignContent {
  background-color: transparent; }

#SignIn .bg_img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

#SignIn .ant-form-explain {
  color: #f5222d;
  margin-top: 8px;
  position: absolute; }

#SignIn .passwordIcon {
  font-size: 20px;
  color: #333; }

#SignIn .passwordInput {
  margin-top: 1.875vw;
  border-bottom: 1px solid #333333;
  box-shadow: 0 0 0px 1000px transparent inset !important; }
  #SignIn .passwordInput .ant-input-prefix {
    left: 0; }
  #SignIn .passwordInput input {
    border: 0; }
    #SignIn .passwordInput input:focus {
      box-shadow: none; }

#SignIn .hide {
  display: none; }

#SignIn .signin_box {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh; }

#SignIn .form_box1 {
  position: absolute;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }
  #SignIn .form_box1 .tit {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    color: white; }
  #SignIn .form_box1 .sysName {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFangSC;
    font-weight: 500;
    color: white;
    margin-top: 8px; }
  #SignIn .form_box1 .welcome {
    font-size: 16px; }

#SignIn .for1 {
  border-radius: 2px;
  border: 1px solid #979797;
  background: white;
  margin-top: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2.08333vw 48px;
  width: 376px;
  height: 378px; }
  #SignIn .for1 .for_t {
    margin-bottom: 36px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFangSC;
    font-weight: 500;
    color: #333333;
    text-align: center; }

#SignIn .input_box {
  border-bottom: 1px solid #333333;
  width: 280px;
  display: flex;
  align-items: center; }
  #SignIn .input_box .int_icon {
    font-size: 20px; }
  #SignIn .input_box input {
    background: none;
    font-size: 14px;
    outline: none;
    border: none;
    padding-left: 8px; }
    #SignIn .input_box input:focus {
      border: none; }
    #SignIn .input_box input::-webkit-input-placeholder {
      /*Webkit browsers 谷歌*/
      color: #c7c7c7 !important; }
    #SignIn .input_box input:-moz-placeholder {
      /*Mozilla Firefox 4 to 8 火狐*/
      color: #c7c7c7 !important; }
    #SignIn .input_box input::moz-placeholder {
      /*Mozilla Firefox 19+ 火狐*/
      color: #c7c7c7 !important; }
    #SignIn .input_box input:-ms-input-placeholder {
      /*Internet Explorer 10+ IE10以上*/
      color: #c7c7c7 !important; }

#SignIn .ant-input::-webkit-input-placeholder {
  color: #c7c7c7 !important; }

#SignIn .remember {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%; }
  #SignIn .remember .fon {
    padding-left: 5px;
    color: #a2a2a2; }

#SignIn .login_btn {
  width: 280px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: white;
  background: #429cff;
  margin-top: 56px;
  border: none;
  border-radius: 2px; }

#SignIn .footer {
  background: transparent; }
  #SignIn .footer .companyName {
    color: #839dba !important; }

@media (max-width: 750px) {
  #SignIn .bg_img {
    position: absolute; }
  #SignIn .signin_box {
    position: absolute; }
    #SignIn .signin_box .footer {
      background-color: transparent;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center; }
      #SignIn .signin_box .footer .footer_d .his {
        color: #fff; }
  #SignIn .for_t {
    display: none; }
  #SignIn .passwordInput {
    margin-top: 0.08533rem;
    background-color: transparent;
    border-bottom: 1px solid white; }
    #SignIn .passwordInput .ant-input-suffix i {
      color: #fff; }
    #SignIn .passwordInput .ant-input-prefix {
      left: 0; }
    #SignIn .passwordInput input {
      background-color: transparent;
      border: 0;
      color: white; }
      #SignIn .passwordInput input:focus {
        box-shadow: none; }
  #SignIn .form_box {
    left: 0;
    width: 100%;
    position: absolute;
    padding-left: 0;
    padding-right: 0;
    transform: translate(0, 0);
    top: 10%; }
    #SignIn .form_box .tit232 {
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: white; }
    #SignIn .form_box .sysName {
      font-family: PingFangSC-Medium, PingFangSC;
      font-weight: 500;
      color: white; }
  #SignIn .input_box {
    border-bottom: 1px solid #f0f2f5;
    width: 100%; }
    #SignIn .input_box input {
      color: #f0f2f5;
      padding-left: 8px; }
      #SignIn .input_box input::-webkit-input-placeholder {
        color: rgba(240, 242, 245, 0.5) !important;
        font-size: 0.048rem; }
  #SignIn .ant-input::-webkit-input-placeholder {
    color: rgba(240, 242, 245, 0.5) !important;
    font-size: 0.048rem; }
  #SignIn .SignContent {
    padding: 0.07333rem; }
  #SignIn .login-form {
    width: 100%; }
    #SignIn .login-form .ant-form-item {
      margin-bottom: 0px; }
      #SignIn .login-form .ant-form-item input {
        font-size: 0.048rem; }
  #SignIn .login_btn {
    width: 100%;
    border-radius: 0.00533rem;
    border: none; }
    #SignIn .login_btn > span {
      word-spacing: 0; }
  #SignIn .for1 {
    background: transparent;
    border: none;
    height: auto;
    margin: auto;
    margin-top: 0.16rem;
    width: 100%;
    padding: 0; }
  #SignIn .remember {
    margin-top: 0.032rem; }
    #SignIn .remember .fon {
      padding-left: 5px;
      color: #f0f2f5; }
    #SignIn .remember .ant-checkbox .ant-checkbox-inner {
      background: transparent;
      color: #1890ff;
      border: 1px solid #fff; }
    #SignIn .remember .ant-checkbox-checked .ant-checkbox-inner {
      border: 1px solid #1890ff; }
    #SignIn .remember .ant-checkbox-checked .ant-checkbox-inner::after {
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0; }
  #SignIn .login_btn {
    margin-top: 0.072rem;
    background-color: #f0f2f5;
    color: #429cff; }
  #SignIn .form_box1 {
    position: absolute;
    top: 0.10667rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%; }
  #SignIn input:-webkit-autofill,
  #SignIn input:-webkit-autofill:hover,
  #SignIn input:-webkit-autofill:focus,
  #SignIn textarea:-webkit-autofill,
  #SignIn textarea:-webkit-autofill:hover,
  #SignIn textarea:-webkit-autofill:focus,
  #SignIn select:-webkit-autofill,
  #SignIn select:-webkit-autofill:hover,
  #SignIn select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s; } }
