@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-DoctorGroup {
  padding: 0 0.04rem;
  /*  .rightArea {
    float: right;
    .cancel {
      color: #429cff;
    }
  }*/
  /*.groupList {
    overflow: scroll;
    height: m-w(1040);
    .noGroup {
      img {
        width: m-w(240);
        height: m-w(240);
        margin-top: m-w(80);
      }
      p {
        font-size: m-w(22);
        margin-top: m-w(40);
        color: #afa7a7;
      }
    }
    .groupItem {
      width: m-w(690);
      height: m-w(198);
      box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
      border-radius: 8px;
      margin: m-w(24) 0;
      padding: m-w(32) m-w(16);
      p {
        margin-bottom: m-w(10);
      }
      .titleArea {
        display: flex;
        .deleteIcon {
          width: m-w(32);
          height: m-w(32);
          margin: m-w(5) m-w(5) 0 0;
        }
        .title {
          padding: 0;
          width: m-w(380);
          font-weight: 500;
          margin-right: m-w(54);
          @include nowrap-elips;
        }
        .detailsArrow {
          height: m-w(25);
          width: m-w(25);
          margin-top: m-w(5);
          margin-left: m-w(5);
        }
        .imgArea {
          width: m-w(136);
          position: relative;
          img {
            width: m-w(40);
            height: m-w(40);
            border-radius: 100px;
            position: absolute;
          }
          img:nth-child(1) {
            left: 0;
            z-index: 5;
          }
          img:nth-child(2) {
            left: m-w(20);
            z-index: 4;
          }
          img:nth-child(3) {
            left: m-w(40);
            z-index: 3;
          }
          img:nth-child(4) {
            left: m-w(60);
            z-index: 2;
          }
          img:nth-child(5) {
            left: m-w(80);
            z-index: 1;
          }
        }
      }
      .contentDes {
        font-size: m-w(26);
        color: #afa7a7;
        text-align: left;
        word-break: break-all;
        height: m-w(80);
        overflow: hidden;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }*/ }
  #Doctor-DoctorGroup > .groupHeader .deleteSure {
    color: #d0021b;
    margin-left: 0.04rem; }
  #Doctor-DoctorGroup .groupList {
    margin-top: 0.02699rem; }
    #Doctor-DoctorGroup .groupList .groupItem {
      background-color: #fff;
      box-shadow: 0 4px 9px 0 rgba(87, 82, 82, 0.1);
      border-radius: 8px;
      padding: 0.02399rem 0.02133rem;
      margin-bottom: 0.02699rem; }
      #Doctor-DoctorGroup .groupList .groupItem p {
        color: #333;
        opacity: 0.5;
        overflow: hidden;
        word-break: break-word;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; }
      #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader {
        display: flex;
        align-items: center;
        margin-bottom: 0.02133rem; }
        #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .deleteIcon {
          width: 0.04267rem;
          height: 0.04267rem;
          margin-right: 0.008rem; }
        #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemTitle {
          width: 55%;
          font-size: 0.04533rem;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
        #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel {
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
          justify-content: flex-end;
          text-align: right; }
          #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel .itemCount {
            white-space: nowrap; }
          #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel .detailsArrow {
            width: 12px;
            height: 12px;
            color: #999; }
          #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel .itemPhoto {
            margin-right: 0.048rem;
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            justify-content: flex-end; }
            #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel .itemPhoto > div {
              width: 0.03333rem; }
            #Doctor-DoctorGroup .groupList .groupItem .groupItemHeader .itemLabel .itemPhoto img {
              display: inline-block;
              border: 1px solid #fff;
              border-radius: 50%;
              width: 0.06667rem; }
