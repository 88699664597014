@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.NewNotis {
  padding: 0.04rem 0.04rem; }
  .NewNotis .navContainer {
    display: flex;
    justify-content: space-between;
    font-size: 0.04rem; }
    .NewNotis .navContainer .returnContiner .iconBack {
      height: 0.05867rem;
      width: 0.05867rem;
      margin-right: 10px; }
    .NewNotis .navContainer .sendNotif {
      font-size: 0.04rem; }
  .NewNotis .notiTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-top: 0.05333rem;
    margin-bottom: 0.02667rem; }
  .NewNotis .notiTitleTextInput {
    min-height: 0.17333rem;
    margin-bottom: 0.07467rem; }
  .NewNotis .notiTypeTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-bottom: 0.02667rem; }
  .NewNotis .notiTypeContainer {
    display: flex; }
    .NewNotis .notiTypeContainer .notiReVisiting {
      font-size: 0.02933rem;
      font-weight: 0.66667rem;
      color: #f5a623;
      background: rgba(245, 166, 35, 0.2);
      padding: 0.01867rem 0.03733rem;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      height: 0.08533rem; }
    .NewNotis .notiTypeContainer .notiVisit {
      font-size: 0.02933rem;
      font-weight: 0.66667rem;
      color: #5aa700;
      background: rgba(90, 167, 0, 0.2);
      padding: 0.01867rem 0.03733rem;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      height: 0.08533rem; }
    .NewNotis .notiTypeContainer .notiQuestionnaire {
      font-size: 0.02933rem;
      font-weight: 0.66667rem;
      color: #429cff;
      background: rgba(66, 156, 255, 0.2);
      padding: 0.01867rem 0.03733rem;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      height: 0.08533rem; }
    .NewNotis .notiTypeContainer .imgIcon {
      height: 0.04533rem;
      width: 0.04533rem;
      margin-left: 0.14667rem;
      margin-top: -0.072rem; }
  .NewNotis .notiSendTimeContainer {
    display: flex;
    color: #333;
    font-size: 0.04267rem;
    font-weight: bold;
    margin-top: 0.088rem;
    align-items: center;
    padding-bottom: 0.02133rem;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
    .NewNotis .notiSendTimeContainer .img {
      margin-left: 0.02133rem;
      height: 0.04267rem;
      width: 0.04267rem; }
  .NewNotis .notiContentMsgTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-top: 0.07467rem;
    margin-bottom: 0.02133rem; }
  .NewNotis .notiContentMsgInput {
    min-height: 0.21333rem;
    margin-bottom: 0.07467rem; }
  .NewNotis .ant-form-item {
    margin-bottom: 0px; }
  .NewNotis .ant-input {
    border: 1px solid rgba(214, 214, 214, 0.64);
    box-shadow: none !important; }
  .NewNotis .ant-input:hover {
    border: 1px solid rgba(214, 214, 214, 0.64);
    box-shadow: none !important; }
