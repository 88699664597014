@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1025px) {
  .psdssContainer .layoutContent {
    padding-bottom: 0px; }
  .psdssContainer .back {
    display: none; }
  .psdssContainer .headerRight {
    display: none; }
  .psdssContainer .headerLeft {
    display: none; }
  .UpdatePsdSuccess {
    display: flex;
    padding-top: 2.23958vw;
    color: #333;
    flex-direction: column;
    background-color: white;
    margin-top: 16px;
    height: calc(100vh - 192px); }
    .UpdatePsdSuccess .back {
      display: none; }
    .UpdatePsdSuccess .successIcon {
      font-size: 1.77083vw; }
    .UpdatePsdSuccess .suctitle {
      display: flex;
      justify-content: center;
      margin-top: 1.04167vw;
      font-size: 0.83333vw; }
    .UpdatePsdSuccess .switchDis {
      display: flex;
      font-size: 12px;
      justify-content: center;
      color: rgba(51, 51, 51, 0.5);
      margin-top: 2.1875vw; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .psdssContainer .layoutContent {
    padding-bottom: 0px; }
  .psdssContainer .back {
    display: none; }
  .psdssContainer .headerRight {
    display: none; }
  .psdssContainer .headerLeft {
    display: none; }
  .UpdatePsdSuccess {
    display: flex;
    padding: 3.98148vw;
    color: #333;
    flex-direction: column;
    background-color: white;
    margin-top: 16px;
    height: calc(100vh - 192px); }
    .UpdatePsdSuccess .successIcon {
      font-size: 3.14815vw; }
    .UpdatePsdSuccess .suctitle {
      display: flex;
      justify-content: center;
      margin-top: 1.85185vw;
      font-size: 1.48148vw; }
    .UpdatePsdSuccess .switchDis {
      display: flex;
      font-size: 12px;
      justify-content: center;
      color: rgba(51, 51, 51, 0.5);
      margin-top: 2.96296vw; } }

@media (max-width: 750px) {
  .UpdatePsdSuccess {
    display: flex;
    justify-content: center;
    padding: 0.05733rem;
    color: #333;
    flex-direction: column;
    margin-top: 0.29333rem; }
    .UpdatePsdSuccess .successIcon {
      font-size: 0.136rem; }
    .UpdatePsdSuccess .suctitle {
      display: flex;
      justify-content: center;
      font-size: 0.04533rem;
      margin-top: 0.09067rem;
      font-size: 0.02133rem; }
    .UpdatePsdSuccess .switchDis {
      display: flex;
      font-size: 0.03467rem;
      justify-content: center;
      color: rgba(51, 51, 51, 0.5);
      margin-top: 0.056rem; }
  .normalModalCmp .container {
    padding: 0.10667rem;
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .normalModalCmp .titlessModal {
    color: #333;
    font-size: 0.04rem;
    display: flex;
    justify-content: center;
    padding: 0px !important; }
  .normalModalCmp .phoneNum {
    color: #333;
    font-size: 0.05067rem;
    font-weight: bold;
    margin-top: 0.02267rem;
    margin-bottom: 34px; }
  .normalModalCmp .konwBtn {
    font-size: 0.04rem;
    color: white;
    background-color: #429cff;
    border-radius: 8px;
    padding: 0.01467rem 0.08rem;
    display: flex;
    justify-content: center; }
  .normalModalCmp .ant-modal-footer {
    display: none; } }
