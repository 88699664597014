@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.editPatientModal {
  width: 0.92rem; }
  .editPatientModal .codeModalContent {
    padding-top: 0.05997rem;
    text-align: center; }
    .editPatientModal .codeModalContent h1 {
      font-size: 0.02933rem; }
    .editPatientModal .codeModalContent .info {
      background: linear-gradient(90deg, #f8fbff 0%, #ecf5ff 100%);
      border-radius: 8px;
      color: #333;
      padding: 0.02399rem 0; }
      .editPatientModal .codeModalContent .info h2 {
        font-size: 0.04533rem;
        margin-bottom: 0.01199rem; }
      .editPatientModal .codeModalContent .info span {
        color: #9a948b;
        opacity: 0.5; }
    .editPatientModal .codeModalContent .diverse {
      background: linear-gradient(270deg, #fffaf3 0%, #fdedd3 100%); }
    .editPatientModal .codeModalContent #simpleContent i {
      color: #f5a623;
      font-size: 0.09067rem; }
    .editPatientModal .codeModalContent #simpleContent header {
      margin-bottom: 0.06447rem; }
    .editPatientModal .codeModalContent #simpleContent strong,
    .editPatientModal .codeModalContent #simpleContent span {
      display: inline-block;
      color: #333;
      font-weight: 500; }
    .editPatientModal .codeModalContent #simpleContent strong {
      font-size: 0.04rem; }
    .editPatientModal .codeModalContent #simpleContent span {
      font-size: 0.03467rem;
      opacity: 0.5;
      margin-top: 0.01799rem;
      margin-bottom: 0.10045rem; }
