@import './include-media.scss';
$line-color: rgba(214, 214, 214, 1);
$theme-color: #00a1e6;
$normal-border-line: 1px solid rgba(214, 214, 214, 0.64);
$bg-color: #f0f2f5; // 背景颜色
$bg-header: white; // 头部背景颜色
$login-bor: #979797;
$header-color: #333333;
$table-sm-color: #d6d6d6;
// 错误
$err-color: #d0021b;
// 底部
$footer-color: #e4e6e9;
// 按钮
$btn-color: #429cff;
$pc-width: 1170px; // pc-屏幕宽度
$moblie-min-width: 500px;
$pad-width: 800px;
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto;
}
$breakpoints: (
  phone: 750px,
  tablet: 1024px,
  desktop: 1170px,
);
@mixin theme-input {
  border: none;
  border-radius: 16px;
  outline: none;
  box-shadow: none;
  border-bottom: $normal-border-line;
}

// .ant-input:-webkit-autofill {
//   -webkit-box-shadow: 0 0 0px 1000px white inset;
//   -webkit-text-fill-color: #333;
// }

@mixin display-justify-content-center {
  display: flex;
  justify-content: center;
}
@mixin display-align-content-center {
  display: flex;
  align-items: center;
}

/*
    常用primary 按钮样式
*/
@mixin theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: $theme-color;
}
/*
      常用普通按钮样式
 */
@mixin theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: $theme-color;
  background: white;
  border: 1px solid $theme-color;
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background: $theme-color;
  }
}
/*  文本截断 css
*/
@mixin nowrap-elips {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*
 模态框基本样式
 */
@mixin normal-modal {
  //比较常用的模态框样式
  .ant-modal-header {
    display: flex;
    justify-content: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    .ant-modal-title {
      display: flex;
      justify-content: center;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }

  .ant-modal-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    .ant-table {
      min-height: 300px;
    }
  }

  .ant-divider-inner-text {
    padding: 0;
  }
}
/* 常用列表基本样式*/
@mixin normal-table {
  .ant-table-row {
    height: 56px;
    line-height: 56px;
  }
  .ant-table-thead {
    height: 48px;
    line-height: 48px;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #ecf5ff;
  }
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #ecf5ff;
  }
  .ant-table-tbody tr:nth-child(2n + 1) {
    border-bottom: none;
    background: #fff;
  }
  .ant-table-tbody tr:nth-child(2n) {
    background: #f8fafd;
    border-bottom: none;
  }
  .ant-table-thead > tr > th {
    background: #f8fafd;
    padding: 0;
    border-bottom: none;
  }
  .ant-table-tbody > tr {
    padding: 0;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 0;
  }
  .ant-table-tbody > tr > td:first-child,
  .ant-table-thead > tr > th:first-child {
    padding-left: 24px;
  }
  .ant-table-tbody > tr > td:last-child,
  .ant-table-thead > tr > th:last-child {
    padding-right: 24px;
  }
  .ant-table-tbody > tr > td:last-child > div,
  .ant-table-thead > tr > th:last-child {
    justify-content: flex-end;
    text-align: right;
  }
  .ant-table-row-hover > td {
    background: unset !important;
  }
  .ant-table-row-hover:hover {
    background: #ecf5ff !important;
  }
}
/* 常用radio */
@mixin radio-more-type {
  display: flex;
  .ant-btn {
    border: none;
    font-weight: bold;
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $theme-color;
    border-color: $theme-color;
  }
  .ant-radio-button-wrapper {
    border: none;
    border-radius: 16px;
    outline: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 16px;
  }

  .ant-radio-button-wrapper-checked::before {
    background-color: white !important;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: #ffffff;
  }
}
.ant-tooltip-inner {
  background-color: white;
  color: rgba($color: #333, $alpha: 0.5);
}
.ant-tooltip-arrow {
  display: none;
}

.toolTipCustom {
  // background-color: red;
  .ant-tooltip-inner {
    background-color: white;
  }
}
.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important;
}
.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
}
.ant-pagination-item-active:hover a {
  color: white;
}
.ant-pagination-item-active:focus a {
  color: white;
}

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
// 去掉输入默认状态
.rest-default-input {
  background: none;
  outline: none;
  border: none;

  &:focus {
    border: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
// .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
//   border: none;
// }
@function p-w($num) {
  @return 100vw / 1920 * $num;
}
@function p-h($num) {
  @return 100vh/1080 * $num;
}

@function pad-w($num) {
  @return 100vw / 1080 * $num;
}

@function m-w($num) {
  @return $num / 750 * 1rem;
}
@function m-h($num) {
  @return $num / 1334 * 1rem;
}
@media screen and (max-width: $pad-width) {
}

@media screen and (max-width: $moblie-min-width) {
}

@include media('>=tablet') {
  // 微信端 时间选择器，电脑显示居中
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@include media('>phone', '<=tablet') {
  // 微信端 时间选择器，电脑显示居中
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@include media('<=phone') {
}
