@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#SurveyExplain .explain_box {
  box-shadow: 0px 0px 0.02133rem 0px rgba(0, 0, 0, 0.1);
  margin-top: 16px;
  background: white;
  padding: 24px 40px; }

#SurveyExplain .content {
  min-height: calc(100vh - 100px - 0.5rem); }

#SurveyExplain .heig {
  display: block;
  height: 0px; }

#SurveyExplain .back {
  float: left; }

#SurveyExplain .mb_name {
  float: left; }

#SurveyExplain .firstCode {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500; }

#SurveyExplain .secondCode {
  font-size: 14px; }

#SurveyExplain .thirdCode {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; }

#SurveyExplain .tit1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: #333333; }

#SurveyExplain .tit2 {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: #333333; }

#SurveyExplain .tit3 {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFangSC;
  font-weight: 400;
  color: #333333; }

#SurveyExplain .ra {
  width: 6px;
  height: 6px;
  background: #429cff;
  display: inline-block;
  border-radius: 100%;
  margin-right: 4px; }

#SurveyExplain .top1 {
  margin-top: 16px; }

#SurveyExplain .top2 {
  margin-top: 8px; }

#SurveyExplain .pointLi {
  list-style: none;
  text-indent: -11.39px;
  padding-left: 11.39px; }
  #SurveyExplain .pointLi span::before {
    content: '●  ';
    color: #429cff;
    font-size: 14px; }
  #SurveyExplain .pointLi > li {
    margin-bottom: 8px; }

#SurveyExplain h4 {
  margin-top: 16px;
  margin-bottom: 8px; }

#SurveyExplain .SHGH .project-container h1,
#SurveyExplain .SHGH .project-container h2 {
  margin-bottom: 2px; }

#SurveyExplain .SHGH th,
#SurveyExplain .SHGH td:not(.rowName),
#SurveyExplain .SHGH .maintitle {
  text-align: center; }

#SurveyExplain .SHGH td:not(.rowName) {
  font-size: 20px;
  padding: 0; }

#SurveyExplain .SHGH .notice li {
  font-weight: bold; }

#SurveyExplain .SHGH .explainArea {
  margin-top: 32px; }

@media screen and (max-width: 500px) {
  #SurveyExplain .layoutContent {
    margin-bottom: 10px;
    background: #f0f2f5; }
  #SurveyExplain .SHGH {
    padding-top: 0.05622rem;
    padding-left: 0.02667rem;
    padding-right: 0.02667rem; }
  #SurveyExplain .header {
    width: 100% !important;
    padding-left: 0.04rem;
    padding-right: 0.04rem; }
    #SurveyExplain .header .header_name {
      position: relative;
      top: 0;
      margin-left: 0.052rem;
      left: -35%;
      float: left;
      display: block; }
    #SurveyExplain .header .userName-dropdown {
      position: relative;
      top: 0;
      left: 0;
      float: right;
      display: block; }
  #SurveyExplain .pointLi {
    list-style: none;
    text-indent: 3px;
    padding-left: 12px; }
  #SurveyExplain .explain_box {
    margin-top: 0.02133rem;
    padding: 0.04267rem 0.04rem !important; }
  #SurveyExplain .explain_box:nth-of-type(1) {
    padding-top: 73px; }
  #SurveyExplain .pointfLi {
    margin-left: -0.04667rem; }
  #SurveyExplain .pointLi span {
    text-indent: -0.04rem;
    margin-left: -0.04rem; } }
