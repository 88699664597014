.loading-self {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background: rgba($color: #ffffff, $alpha: 0.5);
  z-index: 1000;
}

.loading-self .show {
  display: block;
}

.loading-self .hide {
  display: none;
}

.loading-self {
  .ant-spin-spinning {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -22px;
  }
}
