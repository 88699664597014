@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Patient-PatientNotifyList {
  overflow: auto;
  padding: 0 0.04rem; }
  #Patient-PatientNotifyList .noDoctor {
    width: 100%;
    text-align: center;
    margin-top: 0.10667rem; }
  #Patient-PatientNotifyList .noDoctor img {
    width: 0.32rem;
    height: 0.32rem; }
  #Patient-PatientNotifyList .noDoctor p {
    margin-top: 0.05333rem;
    color: #a79f9f; }
  #Patient-PatientNotifyList .notifyItem {
    box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    margin-bottom: 0.032rem;
    padding: 0.02399rem 0.02133rem; }
  #Patient-PatientNotifyList .notifyItem p {
    margin-bottom: 0.02133rem; }
  #Patient-PatientNotifyList .topAreaTitle h1 {
    padding: 0;
    margin: 0;
    font-size: 0.04533rem;
    font-weight: bold;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  #Patient-PatientNotifyList .topAreaTitle span {
    float: right;
    color: #afa7a7;
    font-size: 0.02933rem;
    font-weight: 400;
    line-height: 0.06667rem; }
  #Patient-PatientNotifyList .topLabel {
    padding-top: 0.01799rem;
    position: relative; }
    #Patient-PatientNotifyList .topLabel .time {
      color: #333;
      padding-top: 0.01799rem; }
    #Patient-PatientNotifyList .topLabel .icon_back {
      position: absolute;
      right: 0;
      bottom: 0; }
      #Patient-PatientNotifyList .topLabel .icon_back .redPoint {
        background: #d0021b;
        width: 0.02133rem;
        height: 0.02133rem;
        border-radius: 100%;
        display: inline-block;
        vertical-align: baseline;
        margin-right: 0.016rem; }
      #Patient-PatientNotifyList .topLabel .icon_back .icon {
        font-size: 0.03733rem;
        color: #afa7a7; }
