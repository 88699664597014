@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.theme-normal-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: #00a1e6;
  background: white;
  border: 1px solid #00a1e6;
  width: 150px; }
  .theme-normal-btn:hover, .theme-normal-btn:active, .theme-normal-btn:focus {
    color: #fff;
    background: #00a1e6; }

.theme-primary-btn {
  padding-left: 24px;
  padding-right: 24px;
  color: white;
  background: #00a1e6; }

.qrModalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.05333rem; }

.scan_code_content {
  display: flex;
  justify-content: center;
  background-color: white; }
  .scan_code_content .scan_code_img {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center; }

.scan_qr_discription_url {
  display: flex;
  justify-content: center;
  font-size: 0.03467rem;
  color: rgba(51, 51, 51, 0.5);
  margin-bottom: 0.02667rem;
  margin-top: 0.02667rem; }

.scan_qr_discription {
  margin-top: 0.06667rem;
  font-size: 0.03467rem;
  color: rgba(51, 51, 51, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }
  .scan_qr_discription > img {
    width: 0.03733rem;
    height: 0.03733rem;
    margin-right: 0.02133rem; }

.scan_footer {
  opacity: 0.01;
  background-color: rgba(0, 0, 0, 0); }

.closeBtn {
  display: flex;
  justify-content: flex-end;
  font-size: 0.064rem;
  position: absolute;
  right: 0;
  top: -0.08533rem; }
  .closeBtn .closeModal {
    width: 0.064rem;
    height: 0.064rem; }

.normalModalCmp .ant-modal-body {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center; }

.normalModalCmp .modal-content-view {
  padding: 10px; }

.normalModalCmp .ant-modal-header {
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.normalModalCmp .ant-modal-footer {
  padding: 10px;
  border-top: white;
  background-color: rgba(0, 0, 0, 0); }

.doctorModalCmp .ant-modal-body {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center; }

.doctorModalCmp .ant-modal-header {
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.doctorModalCmp .ant-modal-footer {
  height: 0px;
  border-top: white;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px; }

.doctorModalCmp .qrModalContainer {
  position: relative;
  padding: 0.02667rem; }

.doctorModalCmp .closeBtn {
  position: absolute;
  bottom: -0.16rem;
  left: 50%;
  margin-left: -0.016rem;
  margin-top: 0.26667rem;
  display: inherit;
  right: unset;
  top: unset; }

.mobileQrModalCmp .ant-modal-body {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center; }

.mobileQrModalCmp .ant-modal-header {
  border-bottom: 1px solid rgba(214, 214, 214, 0.64); }

.mobileQrModalCmp .ant-modal-footer {
  height: 0px;
  border-top: white;
  background-color: rgba(0, 0, 0, 0);
  padding: 0px; }

.mobileQrModalCmp .qrModalContainer {
  position: relative;
  padding: 0.05333rem 0.03733rem;
  display: flex;
  justify-content: center; }

.mobileQrModalCmp .closeBtn {
  position: absolute;
  bottom: -0.16rem;
  left: 50%;
  margin-left: -0.016rem;
  margin-top: 0.26667rem; }

.mobileQrModalCmp .mobileQRDis {
  font-size: 0.03467rem;
  margin-top: 0.032rem;
  display: flex;
  justify-content: center; }

@media (max-width: 750px) {
  .moibleEfollowModalCmp .ant-modal-body {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .moibleEfollowModalCmp .ant-modal-header {
    border-bottom: 1px solid rgba(214, 214, 214, 0.64); }
  .moibleEfollowModalCmp .ant-modal-footer {
    height: 0px;
    border-top: white;
    background-color: rgba(0, 0, 0, 0);
    padding: 0px; }
  .moibleEfollowModalCmp .qrModalContainer {
    position: relative;
    padding: 0.02667rem 5px; }
  .moibleEfollowModalCmp .closeBtn {
    position: absolute;
    bottom: -0.16rem;
    left: 50%;
    margin-left: -0.016rem;
    margin-top: 0.26667rem;
    right: unset;
    top: unset; }
  .moibleEfollowModalCmp .mobileQRDis {
    font-size: 0.03467rem;
    display: flex;
    justify-content: center;
    padding-bottom: 0.016rem;
    color: rgba(51, 51, 51, 0.5); }
  .searchGaugeModal {
    width: 0.58667rem !important; }
    .searchGaugeModal .ant-modal-content {
      border-radius: 0; }
    .searchGaugeModal .ant-modal-body {
      width: 0.58667rem !important;
      height: 0.58667rem !important;
      padding: 0.02667rem; }
    .searchGaugeModal .qrModalContainer {
      padding: 0; }
    .searchGaugeModal .ant-modal-footer {
      display: none; }
    .searchGaugeModal .bottom {
      bottom: -0.16rem;
      top: unset;
      left: 0.26667rem;
      right: unset; } }
