.moreAction {
  border-left: 1px solid #d6d6d6;
  padding-left: 7px;
}
.ant-dropdown-menu {
  // padding: 8px;
  padding-right: 0;
}
.ant-dropdown-menu-item {
  height: 40px;
  line-height: 30px;
  // width: 120px;
  // margin-right: 8px;
}
