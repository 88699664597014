@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-DoctorNotifyList {
  height: 100vh;
  overflow: hidden; }
  #Doctor-DoctorNotifyList .topMenu {
    padding: 0 0.04rem; }
    #Doctor-DoctorNotifyList .topMenu .deleteSure {
      color: #d0021b; }
  #Doctor-DoctorNotifyList .personInfo {
    margin: 0 0.04rem; }
  #Doctor-DoctorNotifyList .notifyList {
    margin-top: 0.02399rem;
    padding: 0 0.04rem;
    overflow: auto; }
    #Doctor-DoctorNotifyList .notifyList .notifyItem {
      box-shadow: 0 0 16px 0 rgba(87, 82, 82, 0.1);
      border-radius: 8px;
      margin-bottom: 0.032rem;
      padding: 0.04267rem 0.02133rem;
      color: #333; }
      #Doctor-DoctorNotifyList .notifyList .notifyItem .topArea {
        display: flex;
        align-items: center;
        margin-bottom: 0.01799rem; }
        #Doctor-DoctorNotifyList .notifyList .notifyItem .topArea .deleteIcon {
          width: 0.04267rem;
          height: 0.04267rem;
          margin-right: 0.01067rem; }
        #Doctor-DoctorNotifyList .notifyList .notifyItem .topArea h1 {
          font-size: 0.04533rem;
          font-weight: bold;
          margin: 0;
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
      #Doctor-DoctorNotifyList .notifyList .notifyItem .timeArea {
        min-height: 1.6em; }
        #Doctor-DoctorNotifyList .notifyList .notifyItem .timeArea .time {
          font-size: 0.03467rem;
          opacity: 1; }
        #Doctor-DoctorNotifyList .notifyList .notifyItem .timeArea span {
          opacity: 0.5;
          font-size: 0.02933rem; }
        #Doctor-DoctorNotifyList .notifyList .notifyItem .timeArea .icon_back {
          width: 0.02133rem;
          float: right; }
  #Doctor-DoctorNotifyList .notifyItem p {
    margin-bottom: 0.02133rem; }
  #Doctor-DoctorNotifyList .topArea .title {
    width: 0.66667rem;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.04533rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600; }
  #Doctor-DoctorNotifyList .topArea .time {
    font-size: 0.02933rem;
    font-weight: 400;
    color: #afa7a7;
    line-height: 0.06667rem; }
  #Doctor-DoctorNotifyList .redPoint {
    background: #d0021b;
    width: 0.02133rem;
    height: 0.02133rem;
    border-radius: 100px;
    display: inline-block;
    position: absolute;
    right: 0.05333rem;
    top: 0.016rem; }
  #Doctor-DoctorNotifyList .type {
    padding: 0.012rem 0.02133rem;
    border-radius: 32px;
    font-size: 0.02933rem; }
  #Doctor-DoctorNotifyList .orange {
    color: #f5a623;
    background: #fdedd3; }
  #Doctor-DoctorNotifyList .green {
    color: #5eaa07;
    background: #deedcc; }
  #Doctor-DoctorNotifyList .blue {
    color: #429cff;
    background: #d9ebff; }
