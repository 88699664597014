@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Patient-PatientPhone {
  padding: 0.04rem;
  display: flex;
  flex-direction: column; }
  #Patient-PatientPhone .ant-form-explain {
    text-align: right; }
  #Patient-PatientPhone .closeIcon {
    color: #333; }
  #Patient-PatientPhone .patientTitle {
    display: flex;
    font-size: 0.06133rem;
    font-weight: 600;
    color: #333333;
    margin-bottom: 0.07121rem; }
  #Patient-PatientPhone .containerInput {
    position: relative; }
    #Patient-PatientPhone .containerInput .labelInput {
      color: #429cff;
      font-size: 0.02933rem;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      opacity: 0;
      transition: top 1s, z-index 1s, opacity 1s; }
    #Patient-PatientPhone .containerInput .showLabel {
      top: -0.06747rem;
      z-index: 1;
      opacity: 1; }
    #Patient-PatientPhone .containerInput input {
      padding-left: 0;
      padding-right: 0;
      font-size: 0.04533rem; }
  #Patient-PatientPhone .ant-form-item {
    margin: 0; }
  #Patient-PatientPhone .ant-input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
  #Patient-PatientPhone .ant-input:focus {
    box-shadow: none;
    -webkit-box-shadow: none; }
  #Patient-PatientPhone .codeContiner {
    margin-top: 0.1rem;
    display: flex;
    align-items: center; }
    #Patient-PatientPhone .codeContiner .codeInput {
      flex: 1; }
    #Patient-PatientPhone .codeContiner .action {
      margin-left: 0.06667rem;
      background: #fff;
      border-radius: 0.01067rem;
      border: 1px solid #429cff;
      color: #429cff;
      padding: 0.01333rem 0.03333rem; }
    #Patient-PatientPhone .codeContiner .enable {
      background: #c7e1ff;
      border-color: #c7e1ff;
      color: #8dc3ff; }
  #Patient-PatientPhone .ant-form {
    margin-bottom: 0.16rem; }
  #Patient-PatientPhone .inputFrom {
    margin-bottom: 0.05333rem; }
  #Patient-PatientPhone .inputBottom {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 0.02133rem;
    position: relative; }
  #Patient-PatientPhone .labelInput {
    position: absolute;
    top: 0.10495rem;
    left: 0;
    font-size: 0.02933rem;
    color: #429cff;
    z-index: 0;
    opacity: 0;
    transition: top 1s, z-index 1s, opacity 1s; }
  #Patient-PatientPhone .code {
    top: 0; }
  #Patient-PatientPhone .showLabel {
    top: 0.05247rem;
    z-index: 1;
    opacity: 1; }
  #Patient-PatientPhone .showCode {
    top: -0.08246rem; }
  #Patient-PatientPhone .inputBox {
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    font-size: 0.04533rem; }
  #Patient-PatientPhone .phone {
    margin-top: 0.12rem; }
  #Patient-PatientPhone .verifyCode {
    width: 0.58667rem; }
  #Patient-PatientPhone .codeEnable {
    background: #d9ebff !important;
    border-color: #d9ebff !important;
    color: #73b2f7 !important; }
  #Patient-PatientPhone .sendVerification {
    padding: 0 0.01333rem;
    width: 0.26667rem;
    height: 0.096rem;
    line-height: 0.096rem;
    background: #fff;
    border: 1px solid #429cff;
    color: #429cff;
    font-size: 0.04rem; }
  #Patient-PatientPhone .enable {
    background: #c7e1ff;
    border-color: #c7e1ff;
    color: #fff; }
  #Patient-PatientPhone .backPatient {
    color: #429cff;
    font-size: 0.04rem;
    font-weight: 400;
    display: block;
    margin-bottom: 0.032rem;
    margin-top: 0.19067rem; }
  #Patient-PatientPhone .sure {
    width: 0.92rem;
    height: 0.10667rem;
    line-height: 0.10667rem;
    background: #429cff;
    color: #fff;
    border: none;
    box-shadow: none;
    font-size: 0.04533rem; }
  #Patient-PatientPhone .sure:disabled {
    opacity: 0.6; }
