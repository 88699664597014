@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.NotiDetail {
  padding: 0.02133rem 0.04rem; }
  .NotiDetail .headerTitle {
    color: #333;
    font-size: 0.05067rem;
    font-weight: bold;
    margin-bottom: 0.02133rem;
    margin-top: 0.03333rem; }
  .NotiDetail .notiReVisiting {
    font-size: 0.02933rem;
    font-weight: 0.66667rem;
    color: #f5a623;
    background: rgba(245, 166, 35, 0.2);
    padding: 0.01333rem 0.024rem;
    border-radius: 0.04267rem;
    margin-right: 0.032rem; }
  .NotiDetail .notiVisit {
    font-size: 0.02933rem;
    font-weight: 0.66667rem;
    color: #5aa700;
    background: rgba(90, 167, 0, 0.2);
    padding: 0.01867rem 0.03733rem;
    border-radius: 0.04267rem;
    margin-right: 0.032rem;
    height: 0.08533rem; }
  .NotiDetail .questionnaire {
    font-size: 0.02933rem;
    font-weight: 0.66667rem;
    color: #429cff;
    background: rgba(66, 156, 255, 0.2);
    padding: 0.01333rem 0.024rem;
    border-radius: 0.04267rem;
    margin-right: 0.032rem; }
  .NotiDetail .notiSender {
    display: flex;
    margin-top: 0.02133rem;
    padding-bottom: 0.02133rem;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1); }
    .NotiDetail .notiSender .senderMsg {
      color: #333;
      font-size: 0.03467rem;
      flex: 1; }
      .NotiDetail .notiSender .senderMsg .lineSpace {
        margin-bottom: 0.02133rem; }
    .NotiDetail .notiSender .imgContiner {
      display: flex;
      justify-content: flex-end;
      margin-top: -0.04rem;
      width: 0.17333rem; }
  .NotiDetail .notiMsg {
    margin-top: 0.04267rem;
    color: #333;
    font-size: 0.04rem;
    word-break: break-word; }
