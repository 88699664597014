@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.MyPatient {
  padding: 0 0.04rem; }
  .MyPatient .search {
    margin-top: 0.03333rem;
    margin-bottom: 0.05333rem; }
  .MyPatient .patientList .am-list-body,
  .MyPatient .patientList div.am-list-line {
    border: none; }
  .MyPatient .patientList .itemContainer {
    display: flex; }
    .MyPatient .patientList .itemContainer .imgRow {
      display: flex;
      flex: 1;
      color: rgba(51, 51, 51, 0.5);
      font-size: 0.03467rem;
      justify-content: space-between;
      align-items: center; }
    .MyPatient .patientList .itemContainer .imgPortrait {
      width: 0.08rem;
      height: 0.08rem;
      border-radius: 50%; }
    .MyPatient .patientList .itemContainer .timeRow {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      color: rgba(51, 51, 51, 0.5);
      font-size: 0.03467rem; }
    .MyPatient .patientList .itemContainer .name {
      font-size: 0.04533rem;
      font-weight: bold;
      color: #333;
      max-width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  .MyPatient .patientList .codeContainer {
    display: flex;
    margin-top: 0.03733rem;
    align-items: center; }
    .MyPatient .patientList .codeContainer .codeRow {
      display: flex;
      font-size: 0.04rem;
      color: #333;
      flex: 1; }
      .MyPatient .patientList .codeContainer .codeRow .number {
        margin-left: 0.02rem; }
    .MyPatient .patientList .codeContainer .moreIcon {
      height: 0.03467rem;
      width: 0.03467rem; }
  .MyPatient .patientList .am-list-item {
    border-radius: 8px;
    box-shadow: 0 0 10px 2px rgba(87, 82, 82, 0.09);
    padding: 0.04rem 0.032rem;
    margin-bottom: 0.032rem; }
    .MyPatient .patientList .am-list-item .am-list-line::after {
      height: 0 !important; }
  .MyPatient .patientList .am-list-body::after {
    height: 0px; }
  .MyPatient .patientList .am-list-body::before {
    height: 0px; }
