@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.p_t {
  text-indent: 15px;
  margin-top: 3px; }

.re {
  color: #d0021b;
  font-weight: bolder; }

#GaugeSurvey .mar_hei {
  margin-top: 20px; }

#GaugeSurvey .se_header {
  display: flex;
  font-size: 14px;
  padding: 16px 0; }
  #GaugeSurvey .se_header .fir {
    margin-left: 80px; }

#GaugeSurvey .mus {
  white-space: nowrap;
  font-size: 12px;
  color: #aeaeae; }

#GaugeSurvey .tit_bor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  color: #333; }

#GaugeSurvey .hader_tit {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 99;
  background: #f0f2f5;
  padding: 0 14.32292vw;
  align-items: center;
  height: 54px;
  line-height: 54px; }
  #GaugeSurvey .hader_tit .header_info {
    display: flex;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC; }
  #GaugeSurvey .hader_tit .attention {
    color: #429cff; }
  #GaugeSurvey .hader_tit .disable {
    color: #7f7f7f; }
  #GaugeSurvey .hader_tit .submitBtn {
    width: 80px;
    border-radius: 2px;
    box-shadow: none; }
  #GaugeSurvey .hader_tit .dis_c {
    margin-left: 20px;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#GaugeSurvey .attentionContainer {
  margin-top: 54px;
  color: #919294;
  display: flex;
  font-weight: 500;
  flex-wrap: wrap;
  font-size: 0.67708vw; }
  #GaugeSurvey .attentionContainer .attentionItem {
    width: 33%; }
  #GaugeSurvey .attentionContainer .pointTip {
    width: 66%; }
  #GaugeSurvey .attentionContainer .attentionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px; }
    #GaugeSurvey .attentionContainer .attentionTitle .titleTips {
      width: 6px;
      height: 12px;
      background: #919294;
      display: inline-block;
      margin-right: 8px; }
    #GaugeSurvey .attentionContainer .attentionTitle > div {
      font-weight: 600;
      display: flex;
      align-items: center; }
    #GaugeSurvey .attentionContainer .attentionTitle .expansion {
      cursor: pointer;
      color: #429cff; }
  #GaugeSurvey .attentionContainer .limitHeight {
    height: 56px;
    overflow: hidden; }
  #GaugeSurvey .attentionContainer .attentionContent > div {
    margin-bottom: 5px;
    margin-right: 10px;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    line-height: 15px; }
    #GaugeSurvey .attentionContainer .attentionContent > div span {
      margin-right: 10px;
      overflow: hidden;
      word-break: break-all;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box; }
  #GaugeSurvey .attentionContainer .columns2 span {
    width: 45%; }
  #GaugeSurvey .attentionContainer .columns3 span {
    width: 30%; }

#GaugeSurvey .subjectContainer {
  flex: 1;
  position: relative; }

#GaugeSurvey .gauge_content {
  width: 100%;
  background: white;
  padding: 1.25vw 1.25vw 1.25vw 0.41667vw;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 16px; }
  #GaugeSurvey .gauge_content:nth-child(1) {
    margin-top: 0; }
  #GaugeSurvey .gauge_content .star {
    font-size: 22px;
    line-height: 18px;
    height: 12px;
    margin-right: 5px; }

#GaugeSurvey .wid {
  width: 12.5vw;
  margin-right: 8px; }

#GaugeSurvey .formNavigation {
  height: calc(100vh - 233px);
  overflow: hidden;
  margin-right: 8px; }

#GaugeSurvey #menu {
  height: 100vh;
  width: 12.5vw; }
  #GaugeSurvey #menu .err {
    color: #d0021b !important; }

#GaugeSurvey .heiGauge {
  height: 68px;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  background: #f0f2f5;
  z-index: 1000; }

@media (min-width: 1024px) {
  #GaugeSurvey {
    overflow: hidden; }
    #GaugeSurvey .attention {
      margin-right: 32px; }
    #GaugeSurvey .gauge_box {
      display: flex;
      height: calc(100vh - 233px); }
    #GaugeSurvey .scrl_box {
      width: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%; } }

@media (min-width: 751px) and (max-width: 1024px) {
  #GaugeSurvey .attention {
    margin-right: 32px; } }

@media (max-width: 750px) {
  #GaugeSurvey .gauge_box {
    min-height: calc(100vh - 200px); }
  #GaugeSurvey .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 999; }
  #GaugeSurvey .hader_tit {
    background: white;
    padding: 0.01333rem 0.04rem !important;
    padding-top: 0.01333rem !important;
    width: 100%;
    z-index: 5;
    top: 0.12rem; }
  #GaugeSurvey .tit_bor,
  #GaugeSurvey .tit_box {
    font-size: 0.03467rem; }
  #GaugeSurvey .form_box {
    margin-top: 0.04267rem; }
  #GaugeSurvey .qrTop {
    top: 0 !important; }
  #GaugeSurvey .qrBodyH {
    height: 49px !important; }
  #GaugeSurvey .scrl_box {
    width: 100%;
    margin-top: 0;
    left: 0;
    top: 0;
    bottom: 0; }
  #GaugeSurvey .gauge_box {
    position: relative; }
  #GaugeSurvey #GaugeSurvey {
    overflow: unset; }
  #GaugeSurvey .gauge_content {
    width: 100%;
    padding: 0.04267rem 0.04rem 0 0.04rem;
    box-shadow: none;
    margin-top: 0;
    font-size: 0.03467rem; }
  #GaugeSurvey .heiGauge {
    height: 120px; }
  #GaugeSurvey .attention {
    margin-right: 0.05333rem; }
  #GaugeSurvey .attentionContainer {
    background: #fff;
    margin-top: 108px;
    padding-left: 0.04rem;
    font-size: 0.02933rem; }
    #GaugeSurvey .attentionContainer .attentionItem {
      width: 100%;
      margin-bottom: 0.01333rem; }
    #GaugeSurvey .attentionContainer .attentionContent > div {
      line-height: 0.04rem; }
      #GaugeSurvey .attentionContainer .attentionContent > div > span {
        line-height: 0.04rem;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box; }
    #GaugeSurvey .attentionContainer .limitHeight {
      height: 0.14667rem; }
  #GaugeSurvey .big_moduleName {
    background: #f0f2f5;
    width: 100%;
    z-index: 999;
    padding: 0 0.04rem;
    padding-top: 16px;
    padding-bottom: 8px;
    font-size: 0.03733rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between; }
  #GaugeSurvey .rea {
    color: #d0021b; }
  #GaugeSurvey .mb_icon {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    z-index: 99999;
    width: 0.04rem;
    height: 180px; }
  #GaugeSurvey .formNavigation {
    width: 0.852rem;
    left: 0;
    top: 0;
    position: fixed;
    height: unset;
    z-index: 1000; }
  #GaugeSurvey .left {
    left: 0; }
  #GaugeSurvey .right {
    right: 0.10667rem; }
  #GaugeSurvey .mb_look {
    display: flex;
    align-items: center; }
    #GaugeSurvey .mb_look img {
      width: 0.048rem;
      height: 36px; }
    #GaugeSurvey .mb_look div {
      font-size: 0.04267rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-left: 0.00533rem; }
    #GaugeSurvey .mb_look .mb_visitNumber {
      margin-left: 0.036rem; } }
