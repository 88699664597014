@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-DoctorGroupDetail .colorNull {
  color: rgba(51, 51, 51, 0.2); }

#Doctor-DoctorGroupDetail .contentArea {
  height: 100vh;
  overflow: hidden; }

#Doctor-DoctorGroupDetail .topMenu {
  padding: 0 0.04rem; }

#Doctor-DoctorGroupDetail .scrollArea {
  overflow: auto; }

#Doctor-DoctorGroupDetail .introduction {
  padding: 0 0.04rem; }
  #Doctor-DoctorGroupDetail .introduction .name {
    font-weight: bold;
    font-size: 0.04533rem;
    color: #333333; }
  #Doctor-DoctorGroupDetail .introduction p {
    color: #333;
    margin-bottom: 0.01067rem; }
  #Doctor-DoctorGroupDetail .introduction .remark {
    color: #999;
    font-size: 0.02933rem;
    word-break: break-all; }

#Doctor-DoctorGroupDetail .patientList .noGroup {
  text-align: center; }
  #Doctor-DoctorGroupDetail .patientList .noGroup img {
    width: 0.32rem;
    height: 0.32rem;
    margin-top: 0.10667rem; }
  #Doctor-DoctorGroupDetail .patientList .noGroup p {
    font-size: 0.02933rem;
    margin-top: 0.05333rem;
    color: #afa7a7; }

#Doctor-DoctorGroupDetail .patientList .patientItem {
  width: 0.92rem;
  height: 0.29067rem;
  box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
  border-radius: 8px;
  margin: 0.032rem 0 0.032rem 0.04rem;
  padding: 0.04267rem 0.02133rem; }
  #Doctor-DoctorGroupDetail .patientList .patientItem p {
    margin-bottom: 0.02133rem; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .titlePatient {
    padding: 0;
    font-weight: 400;
    font-size: 0.03467rem;
    color: #999;
    position: relative;
    height: 0.064rem;
    line-height: 0.05067rem;
    display: flex;
    align-items: center; }
    #Doctor-DoctorGroupDetail .patientList .patientItem .titlePatient > span {
      margin-right: 0.05333rem; }
    #Doctor-DoctorGroupDetail .patientList .patientItem .titlePatient .name {
      font-weight: 600;
      font-size: 0.04533rem;
      color: #333; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .sampleSequence,
  #Doctor-DoctorGroupDetail .patientList .patientItem .joinTime {
    font-size: 0.03467rem;
    vertical-align: middle; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .photo {
    width: 0.05333rem;
    height: 0.05333rem;
    border-radius: 50%;
    margin: 0 0.02667rem 0 0; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .edit {
    width: 0.05867rem;
    height: 0.05867rem;
    margin: 0 0 0 0.03467rem; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .remove {
    color: #d0021b;
    border: 1px solid #d0021b;
    font-size: 0.02933rem;
    height: 0.05333rem;
    width: 0.10667rem;
    border-radius: 0.01067rem;
    padding: unset;
    position: absolute;
    right: 0.02133rem; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .imgArea {
    width: 0.18133rem; }
  #Doctor-DoctorGroupDetail .patientList .patientItem .content {
    font-size: 0.03467rem;
    color: #afa7a7;
    text-align: left; }

.refuseModal {
  width: 80vw;
  max-width: 380px; }
  .refuseModal .delPatient {
    font-size: 0.04rem;
    color: #333; }
  .refuseModal .am-modal-button-group-h {
    border: none; }
  .refuseModal .am-modal-content {
    border-radius: 0.032rem; }
