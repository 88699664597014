@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.p_t {
  text-indent: 15px;
  margin-top: 3px; }

.re {
  color: #d0021b;
  font-weight: bolder; }

#PdfResult .layoutContent {
  padding-top: 40px;
  position: relative; }

#PdfResult .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  #PdfResult .bg img {
    width: 100%; }

#PdfResult .head {
  text-align: center;
  margin-top: 10px;
  position: relative; }
  #PdfResult .head img {
    width: 32px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-140px, 10px); }
  #PdfResult .head p {
    margin: 0;
    font-size: 16px;
    line-height: 28px;
    font-weight: bold; }

#PdfResult .content {
  margin: 20px 0;
  padding: 0 50px; }
  #PdfResult .content .clearF {
    clear: both;
    height: 1px; }
  #PdfResult .content .titleName {
    font-size: 14px;
    line-height: 20px;
    color: #0051cb;
    font-weight: bold;
    position: relative; }
    #PdfResult .content .titleName img {
      width: 20px;
      margin-right: 5px; }
  #PdfResult .content .con {
    background-color: #fff;
    position: relative;
    border-radius: 5px;
    padding: 30px 60px; }
    #PdfResult .content .con .pInfo {
      width: 48%;
      color: #333;
      font-size: 12px;
      line-height: 50px;
      position: relative;
      float: left; }
    #PdfResult .content .con .tag_box {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      #PdfResult .content .con .tag_box .tag_itam {
        margin-right: 10px;
        margin-bottom: 5px; }
    #PdfResult .content .con .childCon {
      margin-top: 30px; }
      #PdfResult .content .con .childCon .cTitle {
        color: #000;
        font-size: 12px;
        font-weight: bold; }
      #PdfResult .content .con .childCon .pcon {
        margin: 0;
        font-size: 12px;
        line-height: 20px; }
      #PdfResult .content .con .childCon .form_input {
        resize: none;
        width: 100%;
        border: 1px solid #999999;
        border-radius: 5px;
        height: 380px; }
      #PdfResult .content .con .childCon .redp {
        color: red; }
      #PdfResult .content .con .childCon .op {
        color: orange; }
      #PdfResult .content .con .childCon .bp {
        color: #333; }
    #PdfResult .content .con .dcs {
      margin: 50px 0; }
      #PdfResult .content .con .dcs p {
        text-align: right;
        color: #666666; }
