@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.cardContainer {
  margin-left: 2.08333vw;
  padding: 0.9375vw 0.83333vw;
  width: 14.58333vw;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  cursor: pointer; }
  .cardContainer:first-child {
    margin-left: 0px; }
  .cardContainer .headerContent {
    display: flex;
    justify-content: space-between; }
    .cardContainer .headerContent .titMsg {
      margin-top: 14px; }
      .cardContainer .headerContent .titMsg .title {
        display: flex;
        justify-content: flex-start;
        color: #429cff;
        font-size: 0.72917vw;
        margin-bottom: 0.92593vh; }
      .cardContainer .headerContent .titMsg .discription {
        width: 6.45833vw;
        font-size: 0.625vw;
        color: rgba(51, 51, 51, 0.5);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden; }
    .cardContainer .headerContent .imgIcon {
      width: 5.20833vw;
      height: 5.20833vw;
      background-color: white; }
  .cardContainer .QRContainer {
    display: flex;
    margin-left: 3.125vw;
    margin-top: 2.59259vh;
    align-items: flex-end;
    color: rgba(0, 0, 0, 0.5);
    position: relative; }
    .cardContainer .QRContainer .scan_code_img {
      text-align: center;
      background-color: #dadada;
      width: 6.25vw;
      height: 6.25vw; }
    .cardContainer .QRContainer .hideQRCode {
      font-size: #7f7f7f;
      white-space: nowrap;
      margin-left: 1.04167vw; }

@media (min-width: 751px) and (max-width: 1024px) {
  .cardContainer {
    width: 24.07407vw; }
    .cardContainer .headerContent .titMsg .discription {
      width: 11.11111vw; }
    .cardContainer .headerContent .titMsg .title,
    .cardContainer .headerContent .titMsg .discription {
      font-size: 1.2963vw; }
    .cardContainer .headerContent .imgIcon {
      width: 9.25926vw;
      height: 9.25926vw;
      background-color: white; }
    .cardContainer .QRContainer {
      display: flex;
      margin-left: 5.55556vw;
      margin-top: 1.66667vw;
      align-items: flex-end;
      color: rgba(0, 0, 0, 0.5); }
      .cardContainer .QRContainer .scan_code_img {
        background-color: #dadada; } }
