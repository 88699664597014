@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#AddNotifyType {
  padding: 0 0.04rem; }
  #AddNotifyType .AddNotifyTypeContent {
    padding-top: 0.02999rem; }
    #AddNotifyType .AddNotifyTypeContent h1 {
      font-size: 0.05067rem;
      color: #333;
      font-weight: bold; }
    #AddNotifyType .AddNotifyTypeContent .return {
      background-image: url("../../../assets/moblieImg/img_return_visit.svg");
      color: #f5a623; }
    #AddNotifyType .AddNotifyTypeContent .follow {
      background-image: url("../../../assets/moblieImg/img_follow_up.svg");
      color: #5aa700; }
    #AddNotifyType .AddNotifyTypeContent .questionnaire {
      background-image: url("../../../assets/moblieImg/img_questionnaire.svg");
      color: #429cff; }
    #AddNotifyType .AddNotifyTypeContent > div {
      width: 0.92rem;
      height: 0.29867rem;
      text-align: right;
      background-size: 0.92rem;
      padding-top: 0.09067rem;
      padding-right: 0.07467rem;
      margin-bottom: 0.032rem; }
      #AddNotifyType .AddNotifyTypeContent > div h2 {
        font-size: 0.04rem;
        font-weight: bold;
        color: inherit; }
      #AddNotifyType .AddNotifyTypeContent > div span {
        font-size: 0.02933rem; }
