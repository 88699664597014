@import './assets/css/CommonStyle.scss';

* {
  font-family: PingFangSC-Regular, PingFangSC;
}

body {
  min-height: 480px;
  position: relative;
  color: inherit;
}
.ant-dropdown {
  z-index: 9999999 !important;
}

.m_errMsg {
  color: $err-color;
  font-size: 14px;
}

// 输入框型号

.sm-input {
  width: 422px;
}

.mid-input {
  width: 360px;
}

.big-input {
  width: 422px;
}

.moblieQRimgIcon {
  width: m-w(30);
  height: m-w(30);
}
.moblieQRimgIcon path {
  fill: #429cff;
}
.lengthControl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#SearchGauge {
  @import './pages/Main/SearchGauge/SearchGauge.scss';
}

.am-modal-wrap {
  position: absolute;
  top: unset;
}
.refuseModal {
  font-size: m-w(30);
  .am-modal-body {
    font-weight: 400;

    padding: m-w(100) m-w(50) m-w(106) m-w(50) !important;
    font-family: PingFangSC-Regular, PingFang SC;
  }
  .am-modal-button-group-h::before {
    height: 0 !important;
  }
  .am-modal-footer {
    padding: 0 m-w(40);
  }
  .am-modal-button:first-child {
    color: #d0021b;
    width: m-w(150);
    height: m-w(72);
    line-height: m-w(72);
    margin-bottom: m-w(40);
    margin-right: m-w(20);
  }
  .am-modal-button:last-child {
    width: m-w(150);
    height: m-w(72);
    line-height: m-w(72);
    border-radius: m-w(8);
    border: m-w(2) solid rgba(66, 156, 255, 1) !important;
  }
  .am-modal-button:last-child::before {
    width: 0 !important;
  }
}
#SubmitCase {
}
