@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-DoctorAddGroup {
  padding: 0 0.04rem; }
  #Doctor-DoctorAddGroup .formArea > h1 {
    font-size: 0.04rem;
    font-weight: bold;
    color: #333; }
  #Doctor-DoctorAddGroup .formArea .ant-form-item {
    margin-bottom: 0.07467rem; }
  #Doctor-DoctorAddGroup .formArea .am-input-item {
    width: 0.92rem;
    height: 0.09867rem;
    border-radius: 2px;
    border: 0.00267rem solid #e8e8e8;
    margin-top: 0.008rem;
    padding: 0 0.02rem; }
  #Doctor-DoctorAddGroup .formArea .remark {
    width: 0.92rem;
    height: 0.21333rem;
    border-radius: 2px;
    border: 0.00267rem solid #e8e8e8;
    margin-top: 0.008rem;
    padding: 0; }
  #Doctor-DoctorAddGroup .formArea textarea {
    height: 0.21333rem;
    padding: 0.02rem;
    font-size: 0.04rem;
    line-height: 1.4; }
  #Doctor-DoctorAddGroup .formArea input {
    font-size: 0.04rem; }
  #Doctor-DoctorAddGroup .formArea .patientArea {
    margin-top: 0.02133rem; }
    #Doctor-DoctorAddGroup .formArea .patientArea img {
      height: 0.11733rem; }
    #Doctor-DoctorAddGroup .formArea .patientArea img,
    #Doctor-DoctorAddGroup .formArea .patientArea .patientItem {
      width: 0.11733rem;
      border-radius: 100px;
      display: inline-block;
      margin-right: 0.04267rem;
      margin-bottom: 0.04267rem; }
    #Doctor-DoctorAddGroup .formArea .patientArea .cancel {
      color: #429cff;
      float: right;
      margin-top: 0.02667rem; }
    #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList {
      display: flex;
      flex-wrap: wrap; }
      #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .patientItem {
        position: relative; }
        #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .patientItem .selectIcon {
          position: absolute;
          width: 0.03467rem;
          height: 0.03467rem;
          right: 0;
          top: 0.08667rem;
          margin: 0; }
      #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .detail {
        font-size: 0.02933rem;
        margin-bottom: 0.01067rem;
        padding: 0.02667rem 0.02133rem;
        position: relative;
        width: 0.42667rem;
        height: 0.21333rem;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px; }
      #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .firstLine {
        margin-bottom: 0.02133rem;
        color: #999999;
        display: flex;
        align-items: baseline; }
        #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .firstLine span {
          margin-right: 0.032rem; }
        #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .firstLine span:last-of-type {
          margin: 0; }
        #Doctor-DoctorAddGroup .formArea .patientArea .addedPatientList .firstLine .name {
          max-width: 0.21333rem;
          overflow: hidden;
          font-size: 0.03467rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block; }
