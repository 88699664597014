@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.popQrEfollew {
  position: fixed; }
  .popQrEfollew .qrContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    width: 140px;
    height: 164px; }
    .popQrEfollew .qrContainer .imgQr {
      height: 140px;
      width: 140px; }
  .popQrEfollew .ant-popover {
    text-align: center; }
  .popQrEfollew .disp {
    margin-top: 0.41667vw;
    color: rgba(51, 51, 51, 0.5); }
  .popQrEfollew .ant-popover-placement-left {
    position: fixed; }
  .popQrEfollew .ant-popover-inner-content {
    padding: 10px; }
  .popQrEfollew .effowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.83333vw 0px;
    width: 9.375vw; }
  .popQrEfollew .joinAction {
    background-color: #429cff;
    color: white;
    padding: 0.3125vw 0.625vw;
    margin-top: 0.83333vw;
    border-radius: 2px; }

.disabledColor {
  color: rgba(0, 0, 0, 0.25) !important; }

.ant-form-item {
  margin-bottom: 0px; }

.rowBtnLine {
  border-right: 1px solid #d6d6d6;
  padding: 0px 8px; }
  .rowBtnLine:last-child {
    border-right: none; }

@media (min-width: 1025px) {
  .DiseaseOverviewContainer {
    position: relative;
    /*
          常用普通按钮样式
     */ }
    .DiseaseOverviewContainer .mobielQrIcon {
      display: none; }
    .DiseaseOverviewContainer .layoutContent {
      padding-bottom: 130px; }
    .DiseaseOverviewContainer .mobileAddCase {
      display: none; }
    .DiseaseOverviewContainer .nowrap-elips {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100px; }
    .DiseaseOverviewContainer .sear {
      width: 400px;
      /* margin: 20px auto; */
      margin-left: auto;
      margin-right: auto; }
    .DiseaseOverviewContainer .filterContainer {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    .DiseaseOverviewContainer .ant-form-item {
      display: flex;
      justify-content: space-between;
      margin-right: 20px;
      margin-bottom: 0px; }
    .DiseaseOverviewContainer .customInput {
      display: flex;
      align-items: center;
      margin-right: 2.08333vw;
      margin-top: 0.83333vw; }
    .DiseaseOverviewContainer .searchBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #429cff;
      border-radius: 4px;
      width: 60px;
      text-align: center;
      color: white;
      height: 2.08333vw;
      max-height: 40px; }
    .DiseaseOverviewContainer .visitNumber .ant-input {
      width: 100px; }
    .DiseaseOverviewContainer .tableOpt {
      padding: 0px;
      text-align: center; }
    .DiseaseOverviewContainer .addCaseContainer {
      display: flex;
      align-items: center; }
    .DiseaseOverviewContainer #LayoutComponent {
      min-height: calc(100vh); }
    .DiseaseOverviewContainer .heig {
      display: block;
      height: 60px; }
    .DiseaseOverviewContainer .listTable {
      width: 100%;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      background-color: white; }
    .DiseaseOverviewContainer .pa {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 40px; }
    .DiseaseOverviewContainer .pclistAction {
      width: 200px; }
    .DiseaseOverviewContainer .ant-table-tbody .rowBtnLine:last-child {
      padding-right: 0; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-row {
      height: 56px;
      line-height: 56px; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-thead {
      height: 48px;
      line-height: 48px; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: #ecf5ff; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody tr:nth-child(2n + 1) {
      border-bottom: none;
      background: #fff; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody tr:nth-child(2n) {
      background: #f8fafd;
      border-bottom: none; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th {
      background: #f8fafd;
      padding: 0;
      border-bottom: none; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr {
      padding: 0; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td {
      border-bottom: none;
      padding: 0; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:first-child,
    .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:first-child {
      padding-left: 24px; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:last-child,
    .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      padding-right: 24px; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:last-child > div,
    .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:last-child {
      justify-content: flex-end;
      text-align: right; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-row-hover > td {
      background: unset !important; }
    .DiseaseOverviewContainer .ant-table-body .ant-table-row-hover:hover {
      background: #ecf5ff !important; }
    .DiseaseOverviewContainer .paginationContainer {
      display: flex;
      justify-content: center;
      margin-top: 2.08333vw;
      margin-bottom: 2.08333vw; }
    .DiseaseOverviewContainer .ant-pagination-item {
      border-radius: 100%; }
    .DiseaseOverviewContainer .ant-pagination-prev,
    .DiseaseOverviewContainer .ant-pagination-item-link {
      border-radius: 100%; }
    .DiseaseOverviewContainer .ant-pagination-item-active {
      background: #429cff;
      border: none; }
      .DiseaseOverviewContainer .ant-pagination-item-active a {
        color: white; }
    .DiseaseOverviewContainer .theme-normal-btn {
      padding-left: 24px;
      padding-right: 24px;
      color: #00a1e6;
      background: white;
      border: 1px solid #00a1e6; }
      .DiseaseOverviewContainer .theme-normal-btn:hover, .DiseaseOverviewContainer .theme-normal-btn:active, .DiseaseOverviewContainer .theme-normal-btn:focus {
        color: #fff;
        background: #00a1e6; }
    .DiseaseOverviewContainer .ant-input-affix-wrapper {
      width: 13.54167vw;
      height: 2.08333vw;
      max-height: 40px; }
    .DiseaseOverviewContainer .ant-input-number-input {
      width: 13.54167vw;
      height: 2.08333vw;
      max-height: 40px; }
    .DiseaseOverviewContainer .ant-input-number {
      width: 13.54167vw;
      height: 2.08333vw;
      max-height: 40px; }
    .DiseaseOverviewContainer .loadStatisticBtn {
      border: 1px solid #429cff;
      color: #429cff;
      padding: 0.3125vw 0.625vw;
      background-color: white;
      margin-left: 1.66667vw; }
    .DiseaseOverviewContainer .addOneCase {
      border: 1px solid #429cff;
      color: #429cff;
      padding: 0.3125vw 0.625vw;
      background-color: white; }
    .DiseaseOverviewContainer .listContainer {
      display: flex;
      flex-wrap: wrap; }
    .DiseaseOverviewContainer .listType {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-items: center;
      color: #333;
      margin-top: 1.04167vw;
      margin-bottom: 1.04167vw; }
      .DiseaseOverviewContainer .listType .typeBtn {
        margin-right: 1.04167vw;
        cursor: pointer;
        display: flex;
        align-items: center; }
      .DiseaseOverviewContainer .listType .typeBtnSel {
        margin-right: 1.04167vw;
        color: #429cff;
        cursor: pointer;
        display: flex;
        align-items: center; }
      .DiseaseOverviewContainer .listType .img {
        height: 0.83333vw;
        width: 0.83333vw;
        margin-right: 0.52083vw;
        margin-left: 1.04167vw; }
    .DiseaseOverviewContainer .icon_tips {
      height: 1.04167vw;
      width: 1.04167vw;
      margin-left: 0.46875vw; }
    .DiseaseOverviewContainer .eFellow {
      position: fixed;
      right: 7.44792vw;
      z-index: 900;
      bottom: 200px;
      display: flex;
      flex-direction: column; }
      .DiseaseOverviewContainer .eFellow .efellowIcon {
        height: 2.91667vw;
        width: 2.91667vw;
        margin-bottom: 0.83333vw;
        background-image: url("./../../../assets/img/pc_icon_e_follow_normal.svg");
        background-size: 2.91667vw;
        background-color: white; }
        .DiseaseOverviewContainer .eFellow .efellowIcon:hover,
        .DiseaseOverviewContainer .eFellow .efellowIcon :active {
          background-image: url("./../../../assets/img/pc_icon_e_follow_hover.svg"); }
      .DiseaseOverviewContainer .eFellow .efellowIconActive {
        background-image: url("./../../../assets/img/pc_icon_e_follow_hover.svg"); }
      .DiseaseOverviewContainer .eFellow .qrImgIcon {
        height: 2.91667vw;
        width: 2.91667vw;
        margin-bottom: 0.83333vw;
        background-image: url("./../../../assets/img/pc_icon_qr_code_normal.svg");
        background-size: 2.91667vw;
        background-color: white; }
        .DiseaseOverviewContainer .eFellow .qrImgIcon:hover,
        .DiseaseOverviewContainer .eFellow .qrImgIcon :active {
          background-image: url("./../../../assets/img/pc_icon_qr_code_hover.svg"); }
      .DiseaseOverviewContainer .eFellow .qrImgIconActive {
        background-image: url("./../../../assets/img/pc_icon_qr_code_hover.svg"); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .DiseaseOverviewContainer .layoutContent {
    padding-bottom: 130px; }
  .DiseaseOverviewContainer .listTable {
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    background-color: white; }
  .DiseaseOverviewContainer .mobielQrIcon {
    display: none; }
  .DiseaseOverviewContainer .mobileAddCase {
    display: none; }
  .DiseaseOverviewContainer .paginationContainer {
    display: flex;
    justify-content: center;
    margin-top: 3.7037vw;
    margin-bottom: 1.85185vw; }
  .DiseaseOverviewContainer .icon_tips {
    height: 1.85185vw;
    width: 1.85185vw;
    margin-left: 0.83333vw; }
  .DiseaseOverviewContainer .listContainer {
    display: flex;
    flex-wrap: wrap; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-row {
    height: 56px;
    line-height: 56px; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-thead {
    height: 48px;
    line-height: 48px; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #ecf5ff; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #ecf5ff; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody tr:nth-child(2n + 1) {
    border-bottom: none;
    background: #fff; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody tr:nth-child(2n) {
    background: #f8fafd;
    border-bottom: none; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th {
    background: #f8fafd;
    padding: 0;
    border-bottom: none; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr {
    padding: 0; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 0; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:first-child,
  .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:first-child {
    padding-left: 24px; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:last-child,
  .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:last-child {
    padding-right: 24px; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-tbody > tr > td:last-child > div,
  .DiseaseOverviewContainer .ant-table-body .ant-table-thead > tr > th:last-child {
    justify-content: flex-end;
    text-align: right; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-row-hover > td {
    background: unset !important; }
  .DiseaseOverviewContainer .ant-table-body .ant-table-row-hover:hover {
    background: #ecf5ff !important; }
  .DiseaseOverviewContainer .filterContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .DiseaseOverviewContainer .addCaseContainer {
    display: flex;
    align-items: center; }
  .DiseaseOverviewContainer .listType {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    font-size: 1.2963vw;
    color: #333;
    margin-top: 1.85185vw;
    margin-bottom: 1.66667vw;
    align-items: center; }
    .DiseaseOverviewContainer .listType .typeBtn {
      display: flex;
      align-items: center;
      margin-right: 2.77778vw; }
    .DiseaseOverviewContainer .listType .typeBtnSel {
      display: flex;
      align-items: center;
      margin-right: 2.77778vw;
      color: #429cff; }
    .DiseaseOverviewContainer .listType .img {
      height: 1.66667vw;
      width: 1.66667vw;
      margin-right: 0.83333vw;
      margin-left: 1.04167vw; }
  .DiseaseOverviewContainer .ant-input-affix-wrapper {
    width: 20.37037vw;
    height: 3.7037vw;
    max-height: 40px; }
  .DiseaseOverviewContainer .ant-input-number {
    width: 20.37037vw;
    height: 3.7037vw;
    max-height: 40px; }
  .DiseaseOverviewContainer .ant-input {
    width: 20.37037vw;
    height: 3.7037vw;
    max-height: 40px; }
  .DiseaseOverviewContainer .customInput {
    display: flex;
    align-items: center;
    margin-right: 3.7037vw;
    margin-top: 1.48148vw; }
  .DiseaseOverviewContainer .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #429cff;
    border-radius: 4px;
    width: 7.40741vw;
    text-align: center;
    color: white;
    height: 3.7037vw;
    max-height: 40px; }
  .DiseaseOverviewContainer .loadStatisticBtn {
    border: 1px solid #429cff;
    color: #429cff;
    padding: 0.55556vw 1.11111vw;
    margin-left: 2.96296vw;
    background-color: white; }
  .DiseaseOverviewContainer .addOneCase {
    border: 1px solid #429cff;
    color: #429cff;
    padding: 0.55556vw 1.11111vw;
    background-color: white; }
  .DiseaseOverviewContainer .eFellow {
    position: fixed;
    right: 2.77778vw;
    z-index: 900;
    top: 80vh;
    display: flex;
    flex-direction: column; }
    .DiseaseOverviewContainer .eFellow .efellowIcon {
      height: 4.25926vw;
      width: 4.25926vw;
      margin-bottom: 1.48148vw;
      background-image: url("./../../../assets/img/pc_icon_e_follow_normal.svg");
      background-size: 4.25926vw;
      background-color: white; }
      .DiseaseOverviewContainer .eFellow .efellowIcon:hover,
      .DiseaseOverviewContainer .eFellow .efellowIcon :active {
        background-image: url("./../../../assets/img/pc_icon_e_follow_hover.svg"); }
    .DiseaseOverviewContainer .eFellow .qrImgIcon {
      height: 4.25926vw;
      width: 4.25926vw;
      margin-bottom: 1.48148vw;
      background-image: url("./../../../assets/img/pc_icon_qr_code_normal.svg");
      background-size: 4.25926vw;
      background-color: white; }
      .DiseaseOverviewContainer .eFellow .qrImgIcon:hover,
      .DiseaseOverviewContainer .eFellow .qrImgIcon :active {
        background-image: url("./../../../assets/img/pc_icon_qr_code_hover.svg"); }
  .DiseaseOverviewContainer .pclistAction {
    width: 200px; }
  .DiseaseOverviewContainer .ant-pagination-item {
    border-radius: 100%; }
  .DiseaseOverviewContainer .ant-pagination-prev,
  .DiseaseOverviewContainer .ant-pagination-item-link {
    border-radius: 100%; }
  .DiseaseOverviewContainer .ant-pagination-item-active {
    background: #429cff;
    border: none; }
    .DiseaseOverviewContainer .ant-pagination-item-active a {
      color: white; } }

@media (max-width: 750px) {
  .DiseaseOverviewContainer {
    /* -------------------------- */ }
    .DiseaseOverviewContainer .paginationContainer {
      display: none; }
    .DiseaseOverviewContainer .layoutContent {
      background-color: #f0f2f5; }
    .DiseaseOverviewContainer .preViewHeader {
      background-color: #f0f2f5;
      padding: 0.04267rem 0.04rem 0px 0.04rem; }
    .DiseaseOverviewContainer .chartCardsContainer {
      padding: 0px;
      margin: 0px; }
    .DiseaseOverviewContainer .listContainer {
      padding: 0px;
      background-color: #f0f2f5;
      margin-top: 0.04267rem; }
    .DiseaseOverviewContainer .eFellow {
      display: none; }
    .DiseaseOverviewContainer .mobileAddCase {
      position: fixed;
      z-index: 900;
      top: 0.21333rem;
      right: 0px;
      display: flex;
      background-color: white;
      color: #429cff;
      font-size: 0.04rem;
      height: 0.096rem;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      padding: 0px 0.02667rem;
      align-items: center;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1); }
    .DiseaseOverviewContainer .mobielQrIcon {
      position: fixed;
      z-index: 900;
      bottom: 60px;
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.16rem;
      width: 0.16rem;
      padding: 0.02667rem;
      border-radius: 0.08rem;
      align-items: center;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
      background-color: white; }
      .DiseaseOverviewContainer .mobielQrIcon .img {
        height: 0.04267rem;
        width: 0.04267rem; }
    .DiseaseOverviewContainer .customInput {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0rem;
      width: 100%;
      margin-top: 0.032rem; }
    .DiseaseOverviewContainer .ant-input-affix-wrapper {
      width: 100%; }
    .DiseaseOverviewContainer .filterContainer {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
    .DiseaseOverviewContainer .header_name {
      display: initial; }
    .DiseaseOverviewContainer .ant-input-group {
      display: inline-flex;
      margin-left: 10px;
      margin-bottom: 0.02999rem;
      position: fixed;
      /* background-color: $bg-color; */
      border-left: none !important; }
      .DiseaseOverviewContainer .ant-input-group .ant-input.ant-input-lg {
        order: 1; }
    .DiseaseOverviewContainer .searchBtn {
      display: none; }
    .DiseaseOverviewContainer .fiterBtnContainer {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      flex: 1; }
    .DiseaseOverviewContainer .mobileSearchBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #429cff;
      border-radius: 4px;
      text-align: center;
      color: white;
      height: 30px;
      padding: 0.01867rem 0.04533rem; }
    .DiseaseOverviewContainer .ant-input:focus {
      border-color: #d6d6d6;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: none; }
    .DiseaseOverviewContainer .ant-input-group-addon:last-child {
      width: 40px;
      border-right: none;
      border-radius: 4px 0 0 4px; }
    .DiseaseOverviewContainer .header {
      width: 100% !important;
      padding-left: 0.04rem;
      padding-right: 0.04rem; }
      .DiseaseOverviewContainer .header .header_name {
        position: relative;
        top: 0;
        margin-left: 0.052rem;
        left: -35%;
        float: left;
        display: block; }
      .DiseaseOverviewContainer .header .userName-dropdown {
        position: relative;
        top: 0;
        left: 0;
        float: right;
        display: block; }
    .DiseaseOverviewContainer .sear {
      background-color: white;
      height: 0.07496rem;
      display: flex;
      justify-content: center;
      width: 100%;
      position: fixed;
      top: 65px;
      z-index: 99999;
      margin: 0; }
    .DiseaseOverviewContainer .poHeight {
      height: 100px; }
    .DiseaseOverviewContainer .center {
      display: flex;
      align-items: center;
      justify-content: center; }
    .DiseaseOverviewContainer .content {
      background-color: #f0f2f5; }
    .DiseaseOverviewContainer .mo-dataList {
      background-color: white;
      margin-top: 0.01199rem;
      padding-left: 10px;
      padding-right: 10px; }
    .DiseaseOverviewContainer .upRow {
      font-size: 0.02933rem;
      padding-top: 0.01799rem; }
    .DiseaseOverviewContainer .mid {
      font-size: 0.02933rem;
      padding-top: 0.01799rem;
      color: #333333; }
    .DiseaseOverviewContainer .downRow {
      font-size: 0.03733rem;
      margin-top: 0.02999rem;
      padding-bottom: 0.02924rem;
      border-bottom: 1px dashed rgba(51, 51, 51, 0.2); }
    .DiseaseOverviewContainer .table-row-action {
      width: 100%;
      padding: 0.01799rem 0;
      font-size: 0.03733rem; }
    .DiseaseOverviewContainer .PageInfoCompount {
      display: none; }
    .DiseaseOverviewContainer .heig {
      display: block;
      height: 100px; }
    .DiseaseOverviewContainer .mobileSpin {
      position: fixed; }
    .DiseaseOverviewContainer .ant-btn.ant-input-search-button.ant-btn-primary.ant-btn-lg {
      background-color: traxnsparent;
      border-color: #d6d6d6;
      border-right: none;
      border-radius: 4px 0 0 4px;
      background-color: #f0f2f5;
      /* left: -100%; */ }
    .DiseaseOverviewContainer .ant-input {
      width: 0.48rem !important; }
    .DiseaseOverviewContainer .ant-input-number {
      width: 0.48rem; }
    .DiseaseOverviewContainer .ant-input.ant-input-lg {
      border: 1px solid #d6d6d6; }
    .DiseaseOverviewContainer .ant-input.ant-input-lg {
      width: calc(100% - 60px);
      border-left: none;
      border-radius: 0 4px 4px 0;
      background-color: #f0f2f5; } }
