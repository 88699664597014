@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1024px) {
  .homeContainer .layoutContent {
    display: flex;
    align-items: center;
    justify-content: center; }
  .homeContainer .main-title {
    display: none; }
  .homeContainer .menu_item {
    width: 10.41667vw;
    height: 10.41667vw;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(66, 156, 255, 0.2);
    border-radius: 8px;
    color: #429cff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;
    bottom: 0;
    margin-right: 4.16667vw;
    padding: 2.08333vw 0px;
    cursor: pointer; }
    .homeContainer .menu_item:nth-child(3) {
      margin-right: 0px; }
    .homeContainer .menu_item:hover {
      transform: translateY(-24px);
      position: relative;
      box-shadow: 0px 0px 20px 0px rgba(66, 156, 255, 0.4); }
  .homeContainer .men_con {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center; }
  .homeContainer .menu_name {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    margin-top: 2.1875vw; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .homeContainer .layoutContent {
    display: flex;
    justify-content: center;
    align-items: center; }
  .homeContainer .main-title {
    display: none; }
  .homeContainer .menu_item {
    width: 18.51852vw;
    height: 18.51852vw;
    background: white;
    box-shadow: 0px 0px 20px 0px rgba(66, 156, 255, 0.2);
    color: #429cff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s;
    bottom: 0;
    cursor: pointer;
    margin-right: 7.40741vw;
    padding: 3.7037vw 0px; }
    .homeContainer .menu_item:nth-child(3) {
      margin-right: 0px; }
    .homeContainer .menu_item:hover {
      position: relative;
      bottom: 24px;
      box-shadow: 0px 0px 20px 0px rgba(66, 156, 255, 0.4); }
  .homeContainer .men_con {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center; }
  .homeContainer .menu_name {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFangSC;
    font-weight: 400;
    margin-top: 3.88889vw; }
  .homeContainer .men_box {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (max-width: 750px) {
  .homeContainer {
    width: 100%; }
    .homeContainer .men_box {
      padding: 0rem 0.04667rem;
      padding-top: 0.12rem;
      height: 85vh; }
      .homeContainer .men_box .men_con {
        display: flex;
        width: 100%;
        justify-content: space-around; }
        .homeContainer .men_box .men_con .menu_item {
          display: flex;
          flex-direction: column;
          padding: 0.06rem 0px 0.04267rem 0px;
          width: 0.26667rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 0px 0.02667rem 0px rgba(66, 156, 255, 0.2);
          border-radius: 8px; }
          .homeContainer .men_box .men_con .menu_item .menu_name {
            margin-top: 0.04267rem; }
    .homeContainer .main-title {
      display: flex;
      font-weight: bold;
      margin-top: 0.032rem;
      margin-bottom: 0.248rem; }
    .homeContainer .heig2 {
      display: none; } }
