@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.DoctorAddNotify {
  margin: 0 0.04rem; }
  .DoctorAddNotify .notiTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04rem;
    margin-top: 0.02667rem;
    margin-bottom: 0.02667rem; }
  .DoctorAddNotify .notiTitleTextInput {
    min-height: 0.17333rem;
    margin-bottom: 0.07467rem; }
    .DoctorAddNotify .notiTitleTextInput textarea {
      font-size: 0.04rem; }
  .DoctorAddNotify .am-list-item {
    border: 1px solid #eaeaea; }
  .DoctorAddNotify .notiTypeTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04rem;
    margin-bottom: 0.02667rem; }
  .DoctorAddNotify .notiTypeContainer {
    display: flex; }
    .DoctorAddNotify .notiTypeContainer img {
      position: absolute;
      bottom: -0.00667rem;
      right: -0.02rem;
      width: 0.04533rem;
      height: 0.04533rem; }
    .DoctorAddNotify .notiTypeContainer .notiReVisiting {
      font-size: 0.03467rem;
      font-weight: 0.66667rem;
      color: #f5a623;
      background: rgba(245, 166, 35, 0.2);
      padding: 0 0.024rem;
      line-height: 2.4;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      position: relative; }
    .DoctorAddNotify .notiTypeContainer .notiVisit {
      font-size: 0.03467rem;
      font-weight: 0.66667rem;
      color: #5aa700;
      background: rgba(90, 167, 0, 0.2);
      padding: 0 0.024rem;
      line-height: 2.4;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      position: relative; }
    .DoctorAddNotify .notiTypeContainer .notiQuestionnaire {
      font-size: 0.03467rem;
      font-weight: 0.66667rem;
      color: #429cff;
      background: rgba(66, 156, 255, 0.2);
      padding: 0 0.024rem;
      line-height: 2.4;
      border-radius: 0.04267rem;
      margin-right: 0.032rem;
      position: relative; }
  .DoctorAddNotify .notiSendTimeContainer {
    margin-top: 0.088rem;
    padding-bottom: 0.02133rem; }
    .DoctorAddNotify .notiSendTimeContainer .timeSet {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.03467rem; }
    .DoctorAddNotify .notiSendTimeContainer .ant-form-item {
      margin-bottom: 0;
      padding: 0 0.02133rem; }
    .DoctorAddNotify .notiSendTimeContainer .am-list-item,
    .DoctorAddNotify .notiSendTimeContainer .am-list-line {
      height: 0.07467rem;
      min-height: unset;
      padding: 0; }
    .DoctorAddNotify .notiSendTimeContainer .ant-form-explain {
      position: absolute; }
    .DoctorAddNotify .notiSendTimeContainer #dayAfter {
      width: 0.16rem;
      height: 0.07467rem;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 0.04rem; }
  .DoctorAddNotify .notiContentMsgTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04rem;
    margin-top: 0.07467rem;
    margin-bottom: 0.02133rem; }
  .DoctorAddNotify .notiContentMsgInput {
    min-height: 0.21333rem;
    margin-bottom: 0.07467rem; }
    .DoctorAddNotify .notiContentMsgInput textarea {
      font-size: 0.04rem; }
  .DoctorAddNotify .ant-form-item {
    margin-bottom: 0px; }
