@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

.EditPatient {
  padding: 0 0.04rem; }
  .EditPatient .patientType {
    margin-bottom: 0.05333rem; }
    .EditPatient .patientType h1 {
      font-size: 0.04267rem;
      color: #333;
      font-weight: bold; }
    .EditPatient .patientType input {
      min-height: 0.112rem; }
  .EditPatient .codeTitle {
    display: flex;
    justify-content: flex-start;
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-top: 0.05333rem;
    margin-bottom: 0.02667rem; }
  .EditPatient .codeTextInput {
    min-height: 0.112rem;
    margin-bottom: 0.07467rem; }
  .EditPatient .groupTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-top: 0rem;
    margin-bottom: 0.02133rem; }
  .EditPatient .groupContiner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .EditPatient .groupContiner .groupItem {
      display: flex;
      width: 0.42667rem;
      margin-bottom: 0.032rem; }
      .EditPatient .groupContiner .groupItem .msgCheckContainer {
        padding: 0px 0px 0px 0.024rem; }
        .EditPatient .groupContiner .groupItem .msgCheckContainer .groupItemTitle {
          color: #333333;
          font-size: 0.04rem;
          max-width: 0.37333rem;
          font-weight: 400;
          padding: 0px; }
        .EditPatient .groupContiner .groupItem .msgCheckContainer .discription {
          color: rgba(51, 51, 51, 0.5);
          font-size: 0.03467rem;
          max-width: 0.37333rem;
          flex-wrap: wrap;
          word-break: break-all; }
  .EditPatient .remarkContentMsgTitle {
    color: #333;
    font-weight: bold;
    font-size: 0.04267rem;
    margin-top: 0.07467rem;
    margin-bottom: 0.02133rem; }
  .EditPatient .remarkContentMsgInput {
    min-height: 0.21333rem;
    margin-bottom: 0.07467rem; }
  .EditPatient .ant-checkbox-inner {
    height: 0.04267rem;
    width: 0.04267rem;
    border-radius: 0.02133rem !important;
    border-color: #429cff; }
  .EditPatient .ant-checkbox-checked {
    background-color: #429cff;
    border-color: #429cff;
    height: 0.04267rem;
    width: 0.04267rem;
    border-radius: 0.02133rem !important; }
    .EditPatient .ant-checkbox-checked::after {
      border: none; }
  .EditPatient .ant-form-item {
    margin-bottom: 0px; }
  .EditPatient .ant-input {
    border: 1px solid rgba(214, 214, 214, 0.64);
    box-shadow: none !important; }
  .EditPatient .ant-input:hover {
    border: 1px solid rgba(214, 214, 214, 0.64);
    box-shadow: none !important; }
