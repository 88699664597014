@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1025px) {
  .layoutContainer {
    position: relative;
    background: #f0f2f5;
    overflow: hidden; }
  .layoutContent {
    padding: 64px 14.32292vw 120px;
    width: 100%;
    min-height: 100vh;
    min-width: 1170px; }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    background: white;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    height: 64px;
    background-color: white;
    padding: 0px 14.32292vw;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1); }
    .header .header_name {
      display: flex;
      flex: 1;
      font-weight: bold;
      height: 35px;
      line-height: 35px;
      justify-content: center;
      align-items: center;
      font-size: 16px; }
    .header .headerLeft {
      flex: 1;
      display: flex; }
    .header .headerRight {
      flex: 1;
      display: flex;
      flex-direction: row-reverse; }
  .qrHeight {
    height: 100px;
    background: white;
    z-index: 9999;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0; }
  .gray {
    background: #f0f2f5 !important; }
  .heig {
    height: 103px;
    background-color: white !important;
    display: none; }
  .back {
    padding: 6px 26px;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 2px; }
  .header_name {
    font-family: PingFangSC-Medium, PingFangSC; }
  .footer {
    height: 96px;
    z-index: 100;
    background-color: #e4e6e9ff; }
  .mobile-back {
    display: none; }
  .heig2 {
    height: 42px; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .layoutContent {
    min-height: 100vh;
    padding: 64px 2.77778vw;
    padding-bottom: 120px; }
  .layoutContainer {
    position: relative;
    background: #f0f2f5; }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    background: white;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    height: 64px;
    background-color: white;
    padding: 0px 1.5625vw; }
    .header .header_name {
      display: flex;
      flex: 1;
      font-weight: bold;
      height: 35px;
      line-height: 35px;
      justify-content: center;
      align-items: center; }
    .header .headerLeft {
      display: flex;
      flex: 1; }
    .header .headerRight {
      flex: 1;
      display: flex;
      flex-direction: row-reverse; }
  .qrHeight {
    height: 100px;
    background: white;
    z-index: 9999;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0; }
  .gray {
    background: #f0f2f5 !important; }
  .heig {
    height: 103px;
    background-color: white !important;
    display: none; }
  .back {
    height: 32px;
    line-height: 32px;
    width: 80px;
    text-align: center;
    border: 1px solid #333333;
    color: #333333;
    border-radius: 2px; }
  .header_name {
    font-family: PingFangSC-Medium, PingFangSC; }
  .footer {
    background-color: #e4e6e9ff;
    height: 96px;
    z-index: 100; }
  .mobile-back {
    display: none; }
  .heig2 {
    height: 42px; } }

@media (max-width: 750px) {
  .layoutContainer {
    position: relative;
    background: #f0f2f5; }
  .layoutContent {
    width: 100%;
    background: #fff; }
  .heig2 {
    height: 100px;
    display: none; }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 16px 0.04rem;
    background: white;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    height: 50px;
    background-color: #fff; }
  .header_name {
    position: absolute;
    top: 0.11994rem;
    left: 0.04667rem; }
  .back {
    display: none; }
  .mobile-back {
    font-size: 0.048rem;
    display: block; }
  .userName-dropdown {
    font-size: 0.03733rem; }
    .userName-dropdown .ant-dropdown-link {
      width: 0.16rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
      position: relative; }
      .userName-dropdown .ant-dropdown-link .anticon-down {
        position: absolute;
        right: 0;
        top: 3px; }
  .mb_name {
    display: flex;
    align-items: center; }
    .mb_name div {
      font-size: 0.048rem;
      width: 0.64rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      margin-left: 0.02267rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; } }
