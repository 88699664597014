@import './../../../assets/css/CommonStyle.scss';
@include media('>tablet') {
  .SearchGauge {
    padding: 0px;

    .SearchGaugeContainer {
      display: flex;
      .header-content {
        flex: 1;
        justify-content: flex-end;
        padding-top: p-w(40);
        // color: $btn-color;
        .scan {
          cursor: pointer;
        }
      }
    }
    .search_content {
      flex: 1;
      padding-top: p-w(40);
      .ant-input-group {
        display: flex;
        width: p-w(400);
        height: p-w(40);
      }
      input {
        height: p-w(40);
      }
      .ant-btn {
        height: p-w(40);
      }
      .enable {
        margin-top: 10px;
        color: #929394;
      }
    }

    .sear {
      margin: auto;
      margin-top: 36px;
      width: 320px;
    }
    .bu_na {
      width: 6rem;
    }
    .msg_us {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: $header-color;
      margin-top: 20px;
      color: #999;
    }

    .ser_btn {
      margin-top: 24px;
      padding: 0 15px;
      height: 32px !important;
      img {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: 3px;
      }
    }

    .msg_box {
      // width: 100%;
      width: p-w(400);
      padding: 16px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin-top: 24px;
    }

    .msg_name {
      display: flex;
      align-items: center;
      margin-top: 16px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .web_iconicon_search {
      display: inline;
      margin-right: 5px;
      // margin-right: 0.5rem;
    }
    .na {
      color: $header-color;
      opacity: 0.5;
    }

    .addCase {
      margin-left: 10px;
    }

    .normalModalCmp {
      border-color: red;
    }
    .pCardsTitle {
      color: rgba(51, 51, 51, 1);
      font-size: p-w(16);
      padding-bottom: p-w(16);
    }
    .addNewCase {
      margin-right: p-w(40);
    }
    .searchType {
      padding-top: p-w(24);
      color: #929394;
    }
    .cornerContainer {
      padding-top: p-w(8);
      font-size: 14px;
      cursor: pointer;

      & > div {
        margin-right: p-w(24);
        display: inline-block;
      }
      .cornerBtn {
        background: #e4e6e9;
        height: p-w(32);
        line-height: p-w(32);
        border: none;
        color: #333333;
        padding: 0 p-w(20);
        border-radius: p-w(32);
        &:hover,
        &:active,
        &:focus {
          color: #429cff;
          background: rgba(66, 156, 255, 0.1);
          border: none;
        }
      }
      .cornerBtnSelected {
        border-radius: 16px;
        border: none;
        color: #429cff;
        background: rgba(66, 156, 255, 0.1);
        padding: 0 p-w(20);
        border-radius: p-w(32);
        height: p-w(32) !important;
        line-height: p-w(32) !important;
      }
    }
  }
}
@include media('>phone', '<=tablet') {
  .SearchGauge {
    padding: 0px;

    .SearchGaugeContainer {
      font-size: pad-w(14);
      display: flex;
      padding-top: 10px;

      .header-content {
        flex: 1;
        justify-content: flex-end;
        padding-top: pad-w(24);
        // color: $btn-color;
        .scan {
          cursor: pointer;
        }
      }
      .ant-input-group input {
        font-size: pad-w(14);
      }
    }
    .search_content {
      flex: 1;
      padding-top: pad-w(80);
      .ant-input-group {
        width: pad-w(340);
        height: pad-w(40);
      }
      input {
        width: pad-w(320);
        height: pad-w(40);
      }
      .ant-btn {
        height: pad-w(40);
        font-size: pad-w(14);
        box-shadow: none;
        border: none;
      }
      .enable {
        margin-top: 10px;
        color: #929394;
      }
    }

    .sear {
      margin: auto;
      margin-top: 36px;
      width: 320px;
    }
    .bu_na {
      width: 6rem;
    }
    .msg_us {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      margin-top: 20px;
      color: #999;
    }

    .ser_btn {
      margin-top: 16px;
      img {
        width: 16px;
        height: 16px;
      }
    }

    .msg_box {
      // width: 100%;
      width: pad-w(400);
      padding: 16px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin-top: 24px;
    }

    .msg_name {
      display: flex;
      align-items: center;
      margin-top: 16px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
    .web_iconicon_search {
      display: inline;
      margin-right: pad-w(5);
      // margin-right: 0.5rem;
    }
    .na {
      color: $header-color;
      opacity: 0.5;
    }

    .addCase {
      margin-left: 10px;
    }

    .normalModalCmp {
      border-color: red;
    }
    .pCardsTitle {
      color: rgba(51, 51, 51, 1);
      font-size: pad-w(16);
      padding-bottom: pad-w(16);
    }
    .addNewCase {
      margin-right: pad-w(40);
    }
    .searchType {
      padding-top: pad-w(24);
      color: #929394;
    }
    .cornerContainer {
      padding-top: pad-w(8);
      & > div {
        margin-right: pad-w(24);
        display: inline-block;
        cursor: pointer;
      }
      .cornerBtn {
        background: #e4e6e9;

        border: 1px solid rgba(228, 230, 233, 1);
        color: #333333;
        width: pad-w(96);
        height: pad-w(32);
        border-radius: pad-w(32);
        &:hover,
        &:active,
        &:focus {
          color: #429cff;
          background: rgba(66, 156, 255, 0.1);
          border: 1px solid rgba(66, 156, 255, 0.1);
        }
      }
      .cornerBtnSelected {
        border-radius: 16px;
        border: 1px solid rgba(66, 156, 255, 0.1);
        color: #429cff;
        background: rgba(66, 156, 255, 0.1);
        width: pad-w(96);
        height: pad-w(32);
        border-radius: pad-w(32);
      }
    }
    .titMsg,
    .title,
    .discription {
      font-size: pad-w(14);
    }
  }
}

@include media('<=phone') {
  .SearchGauge {
    font-size: m-w(28);
    padding: 0px;
    height: 100vh;
    background: #fff;
    margin-top: 40px;
    .layoutContent {
      background: #fff;
      height: calc(100vh - 80px - 55px);
    }
    .footer {
      background-color: #fff;
      // display: none;
      .footer_d {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .footer_d .his {
        margin-top: 10px;
        color: rgba($color: #333, $alpha: 0.8);
      }
    }

    .SearchGaugeContainer {
      flex-direction: column;
      padding: 0 20px 10px 20px;
      .header-content {
        flex: 1;
        justify-content: flex-end;
        padding-top: m-w(24);
        // color: $btn-color;
        margin-top: 40px;
        .scan {
          cursor: pointer;
        }
      }
    }
    .search_content {
      margin-top: 10px;
      padding-top: 10px;
      position: relative;
      .web_iconicon_search {
        margin-right: m-w(10);
      }
      .ant-input-group {
        width: 100%;
      }
      .searchInput {
        height: m-w(80);
        line-height: m-w(80);
        background: #f0f2f5;
        border: none;
        border-radius: m-w(8);
        width: 100%;
        padding-left: m-w(55);
      }
      .enable {
        margin-top: 10px;
        color: #929394;
      }
    }
    .blank {
      display: none;
    }

    .ser_btn {
      margin-top: m-w(30);
      width: 100%;
      img {
        width: m-w(28);
        height: m-w(28);
        margin-right: m-w(8);
        margin-bottom: m-w(3);
      }
    }
    .addCaseBtn {
      padding: 0px 5px;
      height: m-w(64);
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(255, 255, 255, 1);
      border-radius: m-w(8);
      color: rgba(66, 156, 255, 1);
      font-size: m-w(30);
      border: 1px solid rgba(66, 156, 255, 1);
      cursor: pointer;
      img {
        width: m-w(30);
        height: m-w(30);
        margin: 0 m-w(5);
      }
    }
    .searchType {
      padding-top: m-w(24);
      color: #929394;
      font-size: m-w(22);
    }
    .ant-input-group {
      height: m-w(80) !important;
      input {
        height: m-w(80) !important;
      }
      .ant-btn {
        height: m-w(80);
      }
    }
    .ant-btn-primary {
      color: white;
      background-color: #429cff;
    }
    .header {
      width: 100% !important;
      padding: {
        left: m-w(30);
        right: m-w(30);
      }
      .header_name {
        position: relative;
        top: 0;
        margin-left: m-w(39);
        left: -35%;
        float: left;
        display: block;
      }
      .userName-dropdown {
        position: relative;
        top: 0;
        left: 0;
        float: right;
        display: block;
      }
    }
    .msg_box {
      box-shadow: none;
      padding-top: 10px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .msg_name {
      font-size: m-w(28);
      width: 43%;
      margin-top: m-w(16);
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      & > div {
        display: inline;
      }
      .na {
        color: #999999;
      }
      .eclips {
        @include nowrap-elips;
        display: inline-block;
        max-width: m-w(180);
      }
    }
    .msg_name:nth-child(2n + 1) {
      width: 57%;
    }
    .msg_name:nth-child(2n) {
      text-align: right;
      justify-content: flex-end;
    }
    .heig {
      height: 15px;
      background-color: white !important;
    }
    .PageInfoCompount {
      display: none;
    }
    .content {
      min-height: calc(100vh - 100px - 0.5rem);
      padding-top: calc(52px + 4.8vw);
    }

    .cornerContainer {
      padding-top: m-w(24);
      & > div {
        margin-right: m-w(48);
        display: inline-block;
        text-align: center;
        height: m-w(64);
        line-height: m-w(64);
        font-size: m-w(28);
      }
      .cornerBtn {
        background: #f0f2f5;
        border: none;
        color: #333333;
        width: m-w(200);

        border-radius: m-w(32);
        &:hover,
        &:active,
        &:focus {
          color: #429cff;
          background: rgba(66, 156, 255, 0.1);
          border: 1px solid rgba(66, 156, 255, 0.1);
        }
      }
      .cornerBtnSelected {
        border-radius: 16px;
        color: #429cff;
        border: none;
        background: rgba(66, 156, 255, 0.1);
        width: m-w(200);
        height: m-w(64);
        border-radius: m-w(32);
      }
    }
    .msg_us {
      font-size: m-w(22);
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      color: $header-color;
      margin-top: 20px;
      color: #999;
    }
    .ser_btn {
      height: m-w(80);
    }
  }
}
