@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#PatientNotifyDetail {
  position: relative;
  height: 100vh;
  margin: 0 0.04rem; }
  #PatientNotifyDetail .topMenu {
    display: flex;
    height: 0.112rem;
    line-height: 0.112rem;
    justify-content: space-between;
    padding: 0 0.04rem; }
    #PatientNotifyDetail .topMenu .back {
      height: 0.04533rem;
      width: 0.04533rem;
      margin-bottom: 0.00667rem; }
  #PatientNotifyDetail .topArea {
    padding: 0.02267rem 0.04rem;
    font-size: 0.04rem;
    font-weight: 400; }
  #PatientNotifyDetail .titleArea {
    padding: 0.01067rem 0 0.02rem 0;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;
    position: relative; }
    #PatientNotifyDetail .titleArea .sendName {
      display: inline-block;
      width: 0.56rem;
      vertical-align: top; }
  #PatientNotifyDetail .titleArea p {
    margin-bottom: 0.02133rem;
    font-size: 0.03467rem;
    font-weight: 400; }
  #PatientNotifyDetail .titleArea .titleNotify {
    font-size: 0.05067rem;
    font-weight: bold;
    padding: 0; }
  #PatientNotifyDetail .operationStatus {
    width: 0.16933rem;
    height: 0.16933rem;
    position: absolute;
    right: 0;
    bottom: 0.04667rem; }
  #PatientNotifyDetail .notifyType {
    padding: 0.016rem 0.02133rem;
    color: #f5a623;
    background: #fdedd3;
    border-radius: 32px;
    font-size: 0.02933rem; }
  #PatientNotifyDetail .contentArea {
    margin: 0.04rem 0;
    height: 0.73333rem;
    word-break: break-word;
    font-size: 0.04rem;
    overflow-y: auto; }
  #PatientNotifyDetail .buttonArea {
    width: 100%;
    position: absolute;
    bottom: 0.13333rem;
    background: #fff; }
    #PatientNotifyDetail .buttonArea .ant-btn {
      border: none;
      width: 0.42667rem;
      display: inline-block;
      height: 0.096rem;
      font-size: 0.04rem; }
      #PatientNotifyDetail .buttonArea .ant-btn::before {
        width: 0;
        height: 0;
        border: none; }
    #PatientNotifyDetail .buttonArea .accept {
      background: #429cff;
      color: #fff; }
    #PatientNotifyDetail .buttonArea .refuse {
      border: 2px solid #d0021b;
      color: #d0021b; }

.patientNotifyModal .tipName {
  color: #333;
  font-size: 0.04rem; }
