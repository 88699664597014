@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Patient-PatientHomePage {
  overflow: auto;
  position: relative; }
  #Patient-PatientHomePage .bgImg {
    width: 1rem;
    height: 0.26667rem;
    position: absolute;
    top: 0;
    left: 0; }
  #Patient-PatientHomePage .personInfo {
    width: 0.92rem;
    height: 0.26667rem;
    box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    background: #ffffff;
    margin: 0 auto;
    position: relative;
    display: flex;
    padding: 0.05333rem 0 0.05333rem 0.032rem;
    margin-top: 0.05333rem; }
    #Patient-PatientHomePage .personInfo .cardBg {
      height: 0.26667rem;
      width: 0.92rem;
      position: absolute;
      right: 0;
      top: 0;
      margin-right: 0;
      z-index: 0; }
    #Patient-PatientHomePage .personInfo .basicInfo {
      z-index: 1; }
  #Patient-PatientHomePage .personInfo .photo {
    width: 0.16rem;
    height: 0.16rem;
    border-radius: 50%;
    margin-right: 0.05333rem;
    z-index: 1; }
  #Patient-PatientHomePage .personInfo span {
    background: #ecf5ff;
    border-radius: 36px;
    margin-right: 0.032rem;
    font-weight: 400;
    font-size: 0.03467rem;
    text-align: center;
    display: inline-block;
    min-width: 0.064rem;
    min-height: 0.064rem;
    line-height: 0.064rem; }
  #Patient-PatientHomePage .age {
    padding: 0 12px; }
  #Patient-PatientHomePage .personInfo span label {
    color: #429cff; }
  #Patient-PatientHomePage .noDoctor {
    width: 100%;
    text-align: center;
    margin-top: 0.10667rem; }
  #Patient-PatientHomePage .noDoctor img {
    width: 0.32rem;
    height: 0.32rem; }
  #Patient-PatientHomePage .noDoctor p {
    margin-top: 0.05333rem;
    color: #a79f9f; }
  #Patient-PatientHomePage .doctorList .titlePatient {
    font-size: 0.056rem;
    font-weight: bold;
    margin: 0.04267rem 0 0.032rem 0.04rem;
    padding: 0; }
  #Patient-PatientHomePage .doctorList ul {
    /* display: flex;
    padding: 0;
    margin-left: m-w(30);*/ }
  #Patient-PatientHomePage .doctor {
    padding: 0;
    margin: 0 0.04rem; }
    #Patient-PatientHomePage .doctor .doctorItem {
      list-style: none;
      display: inline-block;
      position: relative;
      width: 0.43467rem;
      box-shadow: 0px 0px 30px 0px rgba(87, 82, 82, 0.1);
      border-radius: 8px;
      text-align: center;
      margin-right: 0.024rem;
      margin-bottom: 0.02549rem;
      padding-bottom: 0.02999rem; }
    #Patient-PatientHomePage .doctor .hiddenMargin {
      margin-right: 0; }
    #Patient-PatientHomePage .doctor .clear {
      margin-right: 0; }
  #Patient-PatientHomePage .unreadNum {
    position: absolute;
    top: 0.02133rem;
    right: 0.02133rem;
    background: #d0021b;
    border-radius: 12px;
    color: #fff;
    padding: 0 0.01333rem;
    font-size: 0.024rem; }
  #Patient-PatientHomePage .doctorItem img {
    width: 0.11733rem;
    height: 0.11733rem;
    border-radius: 100px;
    margin-top: 0.05333rem; }
  #Patient-PatientHomePage .doctorItem p {
    margin: 0.02133rem; }
  #Patient-PatientHomePage .name {
    font-size: 0.04533rem;
    font-weight: 600;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #333;
    margin-bottom: 0.01799rem; }
  #Patient-PatientHomePage .department span {
    margin: 0 0.02133rem 0.02133rem 0;
    font-size: 0.03467rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333; }
  #Patient-PatientHomePage .hospital,
  #Patient-PatientHomePage .time {
    color: #949191;
    font-size: 0.02933rem; }
