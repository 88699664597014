@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input, #FormTextear .form_input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus, #FormTextear .form_input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

* {
  font-family: PingFangSC-Regular, PingFangSC; }

body {
  min-height: 480px;
  position: relative;
  color: inherit; }

.ant-dropdown {
  z-index: 9999999 !important; }

.m_errMsg {
  color: #d0021b;
  font-size: 14px; }

.sm-input {
  width: 422px; }

.mid-input {
  width: 360px; }

.big-input {
  width: 422px; }

.moblieQRimgIcon {
  width: 0.04rem;
  height: 0.04rem; }

.moblieQRimgIcon path {
  fill: #429cff; }

.lengthControl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

#SearchGauge {
  /*
    常用primary 按钮样式
*/
  /*
      常用普通按钮样式
 */
  /*  文本截断 css
*/
  /*
 模态框基本样式
 */
  /* 常用列表基本样式*/
  /* 常用radio */ }
  #SearchGauge .maxHtmlWidth {
    max-width: 750px !important;
    margin: auto; }
  #SearchGauge .ant-tooltip-inner {
    background-color: white;
    color: rgba(51, 51, 51, 0.5); }
  #SearchGauge .ant-tooltip-arrow {
    display: none; }
  #SearchGauge .toolTipCustom .ant-tooltip-inner {
    background-color: white; }
  #SearchGauge .ant-pagination-prev,
  #SearchGauge .ant-pagination-item-link,
  #SearchGauge .ant-pagination-next,
  #SearchGauge .ant-pagination-item {
    min-width: 25px;
    height: 26px;
    line-height: 26px;
    width: 26px;
    border-radius: 13px !important; }
  #SearchGauge .ant-pagination-item a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px; }
  #SearchGauge .ant-pagination-item-active:hover a {
    color: white; }
  #SearchGauge .ant-pagination-item-active:focus a {
    color: white; }
  #SearchGauge .ant-pagination-item-link {
    display: flex !important;
    justify-content: center;
    align-items: center; }
  #SearchGauge .rest-default-input, #SearchGauge #FormTextear .form_input, #FormTextear #SearchGauge .form_input {
    background: none;
    outline: none;
    border: none; }
    #SearchGauge .rest-default-input:focus, #SearchGauge #FormTextear .form_input:focus, #FormTextear #SearchGauge .form_input:focus {
      border: none; }
  #SearchGauge input:-webkit-autofill,
  #SearchGauge input:-webkit-autofill:hover,
  #SearchGauge input:-webkit-autofill:focus,
  #SearchGauge textarea:-webkit-autofill,
  #SearchGauge textarea:-webkit-autofill:hover,
  #SearchGauge textarea:-webkit-autofill:focus,
  #SearchGauge select:-webkit-autofill,
  #SearchGauge select:-webkit-autofill:hover,
  #SearchGauge select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: #333;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s; }
  @media (min-width: 1024px) {
    #SearchGauge .timePicker450pxCenter {
      max-width: 450px;
      left: 50%;
      transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  #SearchGauge .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }
  @media (min-width: 1025px) {
    #SearchGauge .SearchGauge {
      padding: 0px; }
      #SearchGauge .SearchGauge .SearchGaugeContainer {
        display: flex; }
        #SearchGauge .SearchGauge .SearchGaugeContainer .header-content {
          flex: 1;
          justify-content: flex-end;
          padding-top: 2.08333vw; }
          #SearchGauge .SearchGauge .SearchGaugeContainer .header-content .scan {
            cursor: pointer; }
      #SearchGauge .SearchGauge .search_content {
        flex: 1;
        padding-top: 2.08333vw; }
        #SearchGauge .SearchGauge .search_content .ant-input-group {
          display: flex;
          width: 20.83333vw;
          height: 2.08333vw; }
        #SearchGauge .SearchGauge .search_content input {
          height: 2.08333vw; }
        #SearchGauge .SearchGauge .search_content .ant-btn {
          height: 2.08333vw; }
        #SearchGauge .SearchGauge .search_content .enable {
          margin-top: 10px;
          color: #929394; }
      #SearchGauge .SearchGauge .sear {
        margin: auto;
        margin-top: 36px;
        width: 320px; }
      #SearchGauge .SearchGauge .bu_na {
        width: 6rem; }
      #SearchGauge .SearchGauge .msg_us {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        color: #999; }
      #SearchGauge .SearchGauge .ser_btn {
        margin-top: 24px;
        padding: 0 15px;
        height: 32px !important; }
        #SearchGauge .SearchGauge .ser_btn img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          margin-bottom: 3px; }
      #SearchGauge .SearchGauge .msg_box {
        width: 20.83333vw;
        padding: 16px;
        background: white;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
        margin-top: 24px; }
      #SearchGauge .SearchGauge .msg_name {
        display: flex;
        align-items: center;
        margin-top: 16px; }
        #SearchGauge .SearchGauge .msg_name:nth-child(1) {
          margin-top: 0; }
      #SearchGauge .SearchGauge .web_iconicon_search {
        display: inline;
        margin-right: 5px; }
      #SearchGauge .SearchGauge .na {
        color: #333333;
        opacity: 0.5; }
      #SearchGauge .SearchGauge .addCase {
        margin-left: 10px; }
      #SearchGauge .SearchGauge .normalModalCmp {
        border-color: red; }
      #SearchGauge .SearchGauge .pCardsTitle {
        color: #333333;
        font-size: 0.83333vw;
        padding-bottom: 0.83333vw; }
      #SearchGauge .SearchGauge .addNewCase {
        margin-right: 2.08333vw; }
      #SearchGauge .SearchGauge .searchType {
        padding-top: 1.25vw;
        color: #929394; }
      #SearchGauge .SearchGauge .cornerContainer {
        padding-top: 0.41667vw;
        font-size: 14px;
        cursor: pointer; }
        #SearchGauge .SearchGauge .cornerContainer > div {
          margin-right: 1.25vw;
          display: inline-block; }
        #SearchGauge .SearchGauge .cornerContainer .cornerBtn {
          background: #e4e6e9;
          height: 1.66667vw;
          line-height: 1.66667vw;
          border: none;
          color: #333333;
          padding: 0 1.04167vw;
          border-radius: 1.66667vw; }
          #SearchGauge .SearchGauge .cornerContainer .cornerBtn:hover, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:active, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:focus {
            color: #429cff;
            background: rgba(66, 156, 255, 0.1);
            border: none; }
        #SearchGauge .SearchGauge .cornerContainer .cornerBtnSelected {
          border-radius: 16px;
          border: none;
          color: #429cff;
          background: rgba(66, 156, 255, 0.1);
          padding: 0 1.04167vw;
          border-radius: 1.66667vw;
          height: 1.66667vw !important;
          line-height: 1.66667vw !important; } }

@media (min-width: 751px) and (max-width: 1024px) {
  #SearchGauge .SearchGauge {
    padding: 0px; }
    #SearchGauge .SearchGauge .SearchGaugeContainer {
      font-size: 1.2963vw;
      display: flex;
      padding-top: 10px; }
      #SearchGauge .SearchGauge .SearchGaugeContainer .header-content {
        flex: 1;
        justify-content: flex-end;
        padding-top: 2.22222vw; }
        #SearchGauge .SearchGauge .SearchGaugeContainer .header-content .scan {
          cursor: pointer; }
      #SearchGauge .SearchGauge .SearchGaugeContainer .ant-input-group input {
        font-size: 1.2963vw; }
    #SearchGauge .SearchGauge .search_content {
      flex: 1;
      padding-top: 7.40741vw; }
      #SearchGauge .SearchGauge .search_content .ant-input-group {
        width: 31.48148vw;
        height: 3.7037vw; }
      #SearchGauge .SearchGauge .search_content input {
        width: 29.62963vw;
        height: 3.7037vw; }
      #SearchGauge .SearchGauge .search_content .ant-btn {
        height: 3.7037vw;
        font-size: 1.2963vw;
        box-shadow: none;
        border: none; }
      #SearchGauge .SearchGauge .search_content .enable {
        margin-top: 10px;
        color: #929394; }
    #SearchGauge .SearchGauge .sear {
      margin: auto;
      margin-top: 36px;
      width: 320px; }
    #SearchGauge .SearchGauge .bu_na {
      width: 6rem; }
    #SearchGauge .SearchGauge .msg_us {
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFangSC;
      font-weight: 400;
      margin-top: 20px;
      color: #999; }
    #SearchGauge .SearchGauge .ser_btn {
      margin-top: 16px; }
      #SearchGauge .SearchGauge .ser_btn img {
        width: 16px;
        height: 16px; }
    #SearchGauge .SearchGauge .msg_box {
      width: 37.03704vw;
      padding: 16px;
      background: white;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      margin-top: 24px; }
    #SearchGauge .SearchGauge .msg_name {
      display: flex;
      align-items: center;
      margin-top: 16px; }
      #SearchGauge .SearchGauge .msg_name:nth-child(1) {
        margin-top: 0; }
    #SearchGauge .SearchGauge .web_iconicon_search {
      display: inline;
      margin-right: 0.46296vw; }
    #SearchGauge .SearchGauge .na {
      color: #333333;
      opacity: 0.5; }
    #SearchGauge .SearchGauge .addCase {
      margin-left: 10px; }
    #SearchGauge .SearchGauge .normalModalCmp {
      border-color: red; }
    #SearchGauge .SearchGauge .pCardsTitle {
      color: #333333;
      font-size: 1.48148vw;
      padding-bottom: 1.48148vw; }
    #SearchGauge .SearchGauge .addNewCase {
      margin-right: 3.7037vw; }
    #SearchGauge .SearchGauge .searchType {
      padding-top: 2.22222vw;
      color: #929394; }
    #SearchGauge .SearchGauge .cornerContainer {
      padding-top: 0.74074vw; }
      #SearchGauge .SearchGauge .cornerContainer > div {
        margin-right: 2.22222vw;
        display: inline-block;
        cursor: pointer; }
      #SearchGauge .SearchGauge .cornerContainer .cornerBtn {
        background: #e4e6e9;
        border: 1px solid #e4e6e9;
        color: #333333;
        width: 8.88889vw;
        height: 2.96296vw;
        border-radius: 2.96296vw; }
        #SearchGauge .SearchGauge .cornerContainer .cornerBtn:hover, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:active, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:focus {
          color: #429cff;
          background: rgba(66, 156, 255, 0.1);
          border: 1px solid rgba(66, 156, 255, 0.1); }
      #SearchGauge .SearchGauge .cornerContainer .cornerBtnSelected {
        border-radius: 16px;
        border: 1px solid rgba(66, 156, 255, 0.1);
        color: #429cff;
        background: rgba(66, 156, 255, 0.1);
        width: 8.88889vw;
        height: 2.96296vw;
        border-radius: 2.96296vw; }
    #SearchGauge .SearchGauge .titMsg,
    #SearchGauge .SearchGauge .title,
    #SearchGauge .SearchGauge .discription {
      font-size: 1.2963vw; } }
  @media (max-width: 750px) {
    #SearchGauge .SearchGauge {
      font-size: 0.03733rem;
      padding: 0px;
      height: 100vh;
      background: #fff;
      margin-top: 40px; }
      #SearchGauge .SearchGauge .layoutContent {
        background: #fff;
        height: calc(100vh - 80px - 55px); }
      #SearchGauge .SearchGauge .footer {
        background-color: #fff; }
        #SearchGauge .SearchGauge .footer .footer_d {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
        #SearchGauge .SearchGauge .footer .footer_d .his {
          margin-top: 10px;
          color: rgba(51, 51, 51, 0.8); }
      #SearchGauge .SearchGauge .SearchGaugeContainer {
        flex-direction: column;
        padding: 0 20px 10px 20px; }
        #SearchGauge .SearchGauge .SearchGaugeContainer .header-content {
          flex: 1;
          justify-content: flex-end;
          padding-top: 0.032rem;
          margin-top: 40px; }
          #SearchGauge .SearchGauge .SearchGaugeContainer .header-content .scan {
            cursor: pointer; }
      #SearchGauge .SearchGauge .search_content {
        margin-top: 10px;
        padding-top: 10px;
        position: relative; }
        #SearchGauge .SearchGauge .search_content .web_iconicon_search {
          margin-right: 0.01333rem; }
        #SearchGauge .SearchGauge .search_content .ant-input-group {
          width: 100%; }
        #SearchGauge .SearchGauge .search_content .searchInput {
          height: 0.10667rem;
          line-height: 0.10667rem;
          background: #f0f2f5;
          border: none;
          border-radius: 0.01067rem;
          width: 100%;
          padding-left: 0.07333rem; }
        #SearchGauge .SearchGauge .search_content .enable {
          margin-top: 10px;
          color: #929394; }
      #SearchGauge .SearchGauge .blank {
        display: none; }
      #SearchGauge .SearchGauge .ser_btn {
        margin-top: 0.04rem;
        width: 100%; }
        #SearchGauge .SearchGauge .ser_btn img {
          width: 0.03733rem;
          height: 0.03733rem;
          margin-right: 0.01067rem;
          margin-bottom: 0.004rem; }
      #SearchGauge .SearchGauge .addCaseBtn {
        padding: 0px 5px;
        height: 0.08533rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 0.01067rem;
        color: #429cff;
        font-size: 0.04rem;
        border: 1px solid #429cff;
        cursor: pointer; }
        #SearchGauge .SearchGauge .addCaseBtn img {
          width: 0.04rem;
          height: 0.04rem;
          margin: 0 0.00667rem; }
      #SearchGauge .SearchGauge .searchType {
        padding-top: 0.032rem;
        color: #929394;
        font-size: 0.02933rem; }
      #SearchGauge .SearchGauge .ant-input-group {
        height: 0.10667rem !important; }
        #SearchGauge .SearchGauge .ant-input-group input {
          height: 0.10667rem !important; }
        #SearchGauge .SearchGauge .ant-input-group .ant-btn {
          height: 0.10667rem; }
      #SearchGauge .SearchGauge .ant-btn-primary {
        color: white;
        background-color: #429cff; }
      #SearchGauge .SearchGauge .header {
        width: 100% !important;
        padding-left: 0.04rem;
        padding-right: 0.04rem; }
        #SearchGauge .SearchGauge .header .header_name {
          position: relative;
          top: 0;
          margin-left: 0.052rem;
          left: -35%;
          float: left;
          display: block; }
        #SearchGauge .SearchGauge .header .userName-dropdown {
          position: relative;
          top: 0;
          left: 0;
          float: right;
          display: block; }
      #SearchGauge .SearchGauge .msg_box {
        box-shadow: none;
        padding-top: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap; }
      #SearchGauge .SearchGauge .msg_name {
        font-size: 0.03733rem;
        width: 43%;
        margin-top: 0.02133rem;
        display: flex;
        white-space: nowrap;
        overflow: hidden; }
        #SearchGauge .SearchGauge .msg_name > div {
          display: inline; }
        #SearchGauge .SearchGauge .msg_name .na {
          color: #999999; }
        #SearchGauge .SearchGauge .msg_name .eclips {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: inline-block;
          max-width: 0.24rem; }
      #SearchGauge .SearchGauge .msg_name:nth-child(2n + 1) {
        width: 57%; }
      #SearchGauge .SearchGauge .msg_name:nth-child(2n) {
        text-align: right;
        justify-content: flex-end; }
      #SearchGauge .SearchGauge .heig {
        height: 15px;
        background-color: white !important; }
      #SearchGauge .SearchGauge .PageInfoCompount {
        display: none; }
      #SearchGauge .SearchGauge .content {
        min-height: calc(100vh - 100px - 0.5rem);
        padding-top: calc(52px + 4.8vw); }
      #SearchGauge .SearchGauge .cornerContainer {
        padding-top: 0.032rem; }
        #SearchGauge .SearchGauge .cornerContainer > div {
          margin-right: 0.064rem;
          display: inline-block;
          text-align: center;
          height: 0.08533rem;
          line-height: 0.08533rem;
          font-size: 0.03733rem; }
        #SearchGauge .SearchGauge .cornerContainer .cornerBtn {
          background: #f0f2f5;
          border: none;
          color: #333333;
          width: 0.26667rem;
          border-radius: 0.04267rem; }
          #SearchGauge .SearchGauge .cornerContainer .cornerBtn:hover, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:active, #SearchGauge .SearchGauge .cornerContainer .cornerBtn:focus {
            color: #429cff;
            background: rgba(66, 156, 255, 0.1);
            border: 1px solid rgba(66, 156, 255, 0.1); }
        #SearchGauge .SearchGauge .cornerContainer .cornerBtnSelected {
          border-radius: 16px;
          color: #429cff;
          border: none;
          background: rgba(66, 156, 255, 0.1);
          width: 0.26667rem;
          height: 0.08533rem;
          border-radius: 0.04267rem; }
      #SearchGauge .SearchGauge .msg_us {
        font-size: 0.02933rem;
        font-family: PingFangSC-Regular, PingFangSC;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        color: #999; }
      #SearchGauge .SearchGauge .ser_btn {
        height: 0.10667rem; } }

.am-modal-wrap {
  position: absolute;
  top: unset; }

.refuseModal {
  font-size: 0.04rem; }
  .refuseModal .am-modal-body {
    font-weight: 400;
    padding: 0.13333rem 0.06667rem 0.14133rem 0.06667rem !important;
    font-family: PingFangSC-Regular, PingFang SC; }
  .refuseModal .am-modal-button-group-h::before {
    height: 0 !important; }
  .refuseModal .am-modal-footer {
    padding: 0 0.05333rem; }
  .refuseModal .am-modal-button:first-child {
    color: #d0021b;
    width: 0.2rem;
    height: 0.096rem;
    line-height: 0.096rem;
    margin-bottom: 0.05333rem;
    margin-right: 0.02667rem; }
  .refuseModal .am-modal-button:last-child {
    width: 0.2rem;
    height: 0.096rem;
    line-height: 0.096rem;
    border-radius: 0.01067rem;
    border: 0.00267rem solid #429cff !important; }
  .refuseModal .am-modal-button:last-child::before {
    width: 0 !important; }

.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input, #FormTextear .form_input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus, #FormTextear .form_input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#FormTextear .form_box {
  margin-top: 24px;
  display: flex;
  width: 100%;
  align-items: center;
  position: relative; }

#FormTextear .tit_box {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFangSC;
  font-weight: 500;
  color: rgba(51, 51, 51, 0.7);
  margin-right: 0.41667vw; }

#FormTextear .errMsg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #d0021b;
  font-size: 14px; }

#FormTextear .cl {
  color: #d0021b !important; }

#FormTextear .form_input {
  border: 1px solid #d6d6d6;
  margin-top: 8px;
  width: 98%;
  margin-left: 16px;
  min-height: 80px; }
  #FormTextear .form_input:focus {
    border: 1px solid #d6d6d6; }

#FormTextear .img_box {
  width: 26.04167vw;
  height: 300px;
  margin-top: 16px; }

#FormTextear textarea::-webkit-input-placeholder {
  color: #bfbfbf; }

#FormTextear textarea::-moz-input-placeholder {
  color: #bfbfbf; }

#FormTextear textarea::-ms-input-placeholder {
  color: #bfbfbf; }

@media screen and (max-width: 500px) {
  #FormTextear .img_box {
    width: 100%;
    height: 300px; }
  #FormTextear .form_input {
    margin-left: 0.02133rem; } }
