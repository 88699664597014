@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Doctor-DoctorSetPeriod .doctorSetPeriodHeader {
  margin: 0 0.04rem; }
  #Doctor-DoctorSetPeriod .doctorSetPeriodHeader .deleteSure {
    color: #d0021b;
    margin-left: 0.01333rem; }
  #Doctor-DoctorSetPeriod .doctorSetPeriodHeader .tips {
    font-size: 0.02933rem;
    color: rgba(51, 51, 51, 0.5);
    margin-top: 0.02249rem;
    text-align: right; }
    #Doctor-DoctorSetPeriod .doctorSetPeriodHeader .tips span {
      background: rgba(51, 51, 51, 0.1);
      width: 0.04267rem;
      height: 0.04267rem;
      line-height: 0.04267rem;
      display: inline-block;
      border-radius: 50%;
      text-align: center;
      font-size: 0.024rem;
      color: #333;
      margin: 0 0.01067rem; }

#Doctor-DoctorSetPeriod .periodList {
  overflow: auto;
  padding: 0 0.04rem;
  padding-top: 12px; }
  #Doctor-DoctorSetPeriod .periodList .periodItem {
    box-shadow: 0 0 16px 0 rgba(87, 82, 82, 0.1);
    border-radius: 8px;
    padding: 0.04267rem 0.02133rem;
    margin-bottom: 0.032rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333; }
    #Doctor-DoctorSetPeriod .periodList .periodItem .titlePeriod {
      display: flex;
      align-items: center; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titlePeriod span {
        display: inline-block;
        width: 2.2em;
        height: 2.2em;
        line-height: 2.2em;
        font-size: 0.024rem;
        background-color: rgba(51, 51, 51, 0.1);
        border-radius: 50%;
        text-align: center; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titlePeriod strong {
        font-size: 0.04533rem;
        margin: 0 12px; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titlePeriod .deleteIcon {
        width: 0.04267rem;
        height: 0.04267rem; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titlePeriod .editIcon {
        width: 0.048rem; }
    #Doctor-DoctorSetPeriod .periodList .periodItem .tips {
      opacity: 0.5;
      font-size: 0.02933rem; }
    #Doctor-DoctorSetPeriod .periodList .periodItem .detailsArrow {
      width: 0.024rem;
      margin-left: 0.02667rem;
      vertical-align: middle; }
    #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea {
      display: flex;
      justify-content: space-between; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .tips {
        margin: 0.00667rem 0.04rem 0 0;
        float: right; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .title {
        padding: 0;
        overflow: hidden;
        font-weight: 500;
        margin-right: 0.072rem; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .deleteIcon {
        width: 0.04533rem;
        height: 0.04533rem;
        margin: 0.00667rem 0.032rem 0 0; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .editIcon {
        width: 0.04533rem;
        height: 0.04533rem;
        margin: 0.00667rem 0 0 0.03467rem; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .detailsArrow {
        height: 0.03333rem;
        width: 0.03333rem;
        bottom: 0.02667rem;
        right: 0.00667rem;
        position: absolute; }
      #Doctor-DoctorSetPeriod .periodList .periodItem .titleArea .imgArea {
        width: 0.18133rem; }
    #Doctor-DoctorSetPeriod .periodList .periodItem .content {
      font-size: 0.03467rem;
      color: #afa7a7;
      text-align: left; }
