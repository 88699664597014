@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#Patient-PatientInfo {
  overflow: hidden;
  padding: 0 0.04rem; }
  #Patient-PatientInfo .patientTitle {
    padding: 0;
    font-size: 0.06133rem;
    font-weight: 600;
    color: #333333;
    line-height: 0.08667rem;
    margin: 0.05333rem 0 0 0; }
  #Patient-PatientInfo .infoName {
    padding-top: 0.05997rem;
    position: relative; }
    #Patient-PatientInfo .infoName .tips {
      font-size: 0.02933rem;
      color: #429cff;
      position: absolute;
      left: 0;
      top: 0.04498rem;
      z-index: 0;
      opacity: 0;
      transition: top 300ms, z-index 300ms, opacity 300ms; }
    #Patient-PatientInfo .infoName .showTips {
      top: 0.02249rem;
      z-index: 1;
      opacity: 1; }
    #Patient-PatientInfo .infoName .name {
      padding-left: 0; }
  #Patient-PatientInfo .color {
    color: #429cff; }
  #Patient-PatientInfo .am-list-item {
    border-bottom: 1px solid #e8e8e8;
    padding: 0; }
  #Patient-PatientInfo .birthDate {
    margin-top: 0.05333rem;
    font-size: 0.04533rem;
    padding-bottom: 0.01333rem; }
    #Patient-PatientInfo .birthDate .am-list-content,
    #Patient-PatientInfo .birthDate .am-list-extra {
      padding-bottom: 0; }
    #Patient-PatientInfo .birthDate .am-list-extra {
      color: #429cff;
      float: right; }
  #Patient-PatientInfo .calendar {
    width: 0.04rem;
    height: 0.04rem;
    margin-left: 0.01333rem;
    margin-bottom: 0.008rem; }
  #Patient-PatientInfo .sexChoose {
    margin-top: 0.096rem; }
  #Patient-PatientInfo .imgArea {
    display: flex; }
    #Patient-PatientInfo .imgArea > div {
      position: relative; }
    #Patient-PatientInfo .imgArea img {
      width: 0.21333rem;
      height: 0.21333rem;
      margin-right: 0.21333rem; }
    #Patient-PatientInfo .imgArea p {
      width: 0.21333rem;
      margin-right: 0.21333rem;
      margin-top: 0.02667rem;
      text-align: center;
      margin-left: 0.004rem; }
    #Patient-PatientInfo .imgArea .choose {
      left: 0.08667rem;
      top: 0.18267rem;
      position: absolute;
      width: 0.04533rem;
      height: 0.04533rem; }
  #Patient-PatientInfo .nextStep {
    width: 0.92rem;
    height: 0.10667rem;
    line-height: 0.10667rem;
    background: #429cff;
    color: #fff;
    margin-top: 0.19067rem;
    border: none;
    box-shadow: none;
    font-size: 0.04533rem; }
  #Patient-PatientInfo .patient-date {
    border-bottom: 1px solid #ddd;
    padding-top: 0.08996rem;
    padding-bottom: 0.01199rem; }
    #Patient-PatientInfo .patient-date > div {
      display: inline-block;
      font-size: 0.04533rem; }
      #Patient-PatientInfo .patient-date > div img {
        width: 0.04533rem;
        margin-left: 0.01867rem;
        vertical-align: middle; }
    #Patient-PatientInfo .patient-date > span {
      float: right;
      font-size: 0.04533rem;
      padding-right: 0.02rem; }
