@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 1024px) {
  .chartCardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between; }
  .chartCard {
    background: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 1.25vw;
    width: 23.5%;
    height: 20.3125vw;
    min-width: 180px;
    margin-right: 2%;
    margin-bottom: 1.25vw;
    min-height: 290px; }
  .chartCardHig {
    background: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding-top: 24px;
    width: 49%;
    height: 20.3125vw;
    min-width: 410px;
    margin-bottom: 1.25vw;
    min-height: 290px; }
  .title {
    font-weight: bold;
    display: flex;
    font-size: 16px;
    justify-content: center; }
  .chartContent {
    padding-top: 2.08333vw; }
  .chartMsg {
    display: flex;
    justify-content: space-between;
    padding-top: 1.85185vh; }
    .chartMsg .statisticsMsg {
      display: flex;
      width: 50%;
      justify-content: flex-end;
      padding-right: 10px; }
      .chartMsg .statisticsMsg:last-child {
        margin-left: 10px;
        justify-content: flex-start;
        padding-left: 28px; }
      .chartMsg .statisticsMsg .complete {
        font-size: 14px;
        display: flex;
        margin-left: -18px;
        align-items: center;
        margin-bottom: 0.83333vw; }
      .chartMsg .statisticsMsg .statisticsContent {
        font-weight: bold;
        font-size: 1.35417vw;
        margin-bottom: 5px; }
    .chartMsg .dot {
      height: 8px;
      width: 8px;
      background-color: #8db545;
      border-radius: 4px;
      margin-right: 10px; }
  .msgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.85185vh; } }

@media (min-width: 751px) and (max-width: 1024px) {
  .chartCardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between; }
  .chartCard {
    background: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding: 2.22222vw;
    width: 23.5%;
    height: 29.62963vw;
    min-width: 100px;
    min-height: 200px;
    margin-right: 2%;
    margin-bottom: 2.22222vw; }
  .chartCardHig {
    background: white;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
    padding-top: 24px;
    width: 49%;
    height: 29.62963vw;
    min-width: 300px;
    min-height: 200px;
    margin-bottom: 2.22222vw; }
  .title {
    font-weight: bold;
    display: flex;
    font-size: 16px;
    justify-content: center; }
  .chartContent {
    padding-top: 0.92593vw; }
  .chartMsg {
    display: flex;
    justify-content: center; }
    .chartMsg .statisticsMsg {
      display: flex;
      width: 50%;
      justify-content: flex-end;
      padding-right: 10px; }
      .chartMsg .statisticsMsg:last-child {
        justify-content: flex-start;
        padding-left: 2.59259vw; }
      .chartMsg .statisticsMsg .complete {
        font-size: 14px;
        display: flex;
        margin-left: -18px;
        align-items: center;
        margin-bottom: 0.83333vw; }
    .chartMsg .dot {
      height: 8px;
      width: 8px;
      background-color: #8db545;
      border-radius: 4px;
      margin-right: 5px;
      margin-top: 6px; }
  .msgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.85185vh; }
  .statisticsContent {
    font-weight: bold;
    margin-bottom: 1.38889vh; } }

@media (max-width: 750px) {
  .chartCardsContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 10px; }
    .chartCardsContainer .chartCard {
      display: flex;
      flex-direction: column;
      background-color: white;
      width: 100%;
      padding: 0.032rem 0.064rem 0.064rem 0.064rem;
      margin-bottom: 10px; }
    .chartCardsContainer .chartCardHig {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-bottom: 0.032rem;
      margin-bottom: 10px;
      min-width: 0px;
      background-color: white; }
      .chartCardsContainer .chartCardHig .title {
        padding-left: 24px;
        padding-top: 0.032rem;
        font-size: 0.03733rem;
        font-weight: bold;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 0.04667rem; }
    .chartCardsContainer .chartContent {
      display: flex;
      flex-direction: row;
      align-items: center; }
    .chartCardsContainer .title {
      font-size: 0.03733rem;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 0.04667rem; }
    .chartCardsContainer .chartMsg {
      display: flex;
      justify-content: space-between;
      margin-left: 8%;
      flex: 1; }
      .chartCardsContainer .chartMsg .statisticsMsg {
        display: flex;
        flex-direction: row;
        min-width: 0.16rem;
        margin-left: 15%;
        width: 45%; }
        .chartCardsContainer .chartMsg .statisticsMsg:last-child {
          margin-right: 0rem; }
      .chartCardsContainer .chartMsg .msgContainer {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .chartCardsContainer .chartMsg .msgContainer .dot {
          height: 8px;
          width: 8px;
          background-color: #8db545;
          border-radius: 4px;
          margin-right: 10px; }
        .chartCardsContainer .chartMsg .msgContainer .complete {
          font-size: 0.03733rem;
          display: flex;
          margin-left: -18px;
          align-items: center; }
        .chartCardsContainer .chartMsg .msgContainer .statisticsContent {
          font-size: 0.056rem;
          font-weight: bold;
          margin-top: 0.01867rem;
          margin-bottom: 0.02rem; } }
