@charset "UTF-8";
.maxHtmlWidth {
  max-width: 750px !important;
  margin: auto; }

/*
    常用primary 按钮样式
*/
/*
      常用普通按钮样式
 */
/*  文本截断 css
*/
/*
 模态框基本样式
 */
/* 常用列表基本样式*/
/* 常用radio */
.ant-tooltip-inner {
  background-color: white;
  color: rgba(51, 51, 51, 0.5); }

.ant-tooltip-arrow {
  display: none; }

.toolTipCustom .ant-tooltip-inner {
  background-color: white; }

.ant-pagination-prev,
.ant-pagination-item-link,
.ant-pagination-next,
.ant-pagination-item {
  min-width: 25px;
  height: 26px;
  line-height: 26px;
  width: 26px;
  border-radius: 13px !important; }

.ant-pagination-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px; }

.ant-pagination-item-active:hover a {
  color: white; }

.ant-pagination-item-active:focus a {
  color: white; }

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.rest-default-input {
  background: none;
  outline: none;
  border: none; }
  .rest-default-input:focus {
    border: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s; }

@media (min-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

@media (min-width: 751px) and (max-width: 1024px) {
  .timePicker450pxCenter {
    max-width: 450px;
    left: 50%;
    transform: translateX(-50%); } }

#addNotify {
  padding: 0 0.04rem; }
  #addNotify .addNotifyContent header {
    background-image: url("../../../assets/moblieImg/img_add_news_patient.svg");
    background-size: 0.92rem;
    position: relative;
    padding: 0.04498rem 0.17067rem; }
    #addNotify .addNotifyContent header img {
      width: 0.11733rem;
      border-radius: 50%;
      position: absolute;
      top: 0.04498rem;
      left: 0.02133rem; }
    #addNotify .addNotifyContent header b {
      font-size: 0.04533rem;
      font-weight: bold;
      color: #333;
      margin-bottom: 0;
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
    #addNotify .addNotifyContent header div {
      font-size: 0.03467rem;
      color: #333;
      opacity: 0.5; }
      #addNotify .addNotifyContent header div span {
        padding-left: 0.05333rem; }
  #addNotify .addNotifyContent h1 {
    font-weight: bold;
    font-size: 0.05067rem;
    color: #333; }
  #addNotify .container {
    box-shadow: 0 0 30px 0 rgba(87, 82, 82, 0.1);
    margin-bottom: 0.02699rem;
    border-radius: 8px;
    padding: 0.02399rem 0.02133rem; }
    #addNotify .container p {
      opacity: 0.5;
      margin-bottom: 0.01199rem; }
    #addNotify .container h2,
    #addNotify .container b {
      font-weight: bold;
      font-size: 0.04rem;
      color: #333; }
    #addNotify .container .contentRight {
      float: right;
      font-size: 0.04rem;
      color: #333;
      font-weight: 500; }
      #addNotify .container .contentRight > span {
        opacity: 0.2;
        font-style: normal;
        font-weight: 500; }
      #addNotify .container .contentRight .moreIcon {
        width: 0.03467rem;
        height: 0.03467rem;
        margin-left: 8px;
        vertical-align: unset; }
    #addNotify .container hr {
      border: none;
      height: 2px;
      background-color: #333;
      opacity: 0.1;
      margin: 0.02999rem 0; }
